import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
    title: {
        textTransform: 'uppercase',
        fontFamily: 'Antic Didone',
        letterSpacing: '6px',
        fontSize: '34px'
    },
    chapter: {
        // textTransform: 'uppercase'
    },
    paperClass: {
        '& div': {
            padding:  '25px 50px',
            lineHeight: '13px',
            fontSize: '13px',
        },
        '& p': {
            fontWeight: 300,
            margin: '0 0 5px 0',
        },
        textAlign: 'center',
    },
    timeClass: {
        // marginBottom: '10px',
    },
    locationName: {
        textTransform: 'uppercase',
        fontWeight: '500 !important',
    },
    divider: {
        width: '35px',
        margin: '10px 0',
    },
}));

function Chapter(props) {

    const classes = useStyles();
    const {id, theme, title, description, currentChapterID, date, time, address, locationName, url, urlLabel} = props;
    const classList = id === currentChapterID ? "step active" : "step";
    return (
        <Paper elevation={3} className={classes.paperClass}>
        <div id={id} className={`${classList} ${theme}`}>
            <div className={`${classes.chapter}`}>
                { title &&
                    <h3 className={classes.title}>{title}</h3>
                }
                { date &&
                    <p>{date}</p>
                }
                { time &&
                    <p className={`${classes.timeClass}`}>{time}</p>
                }
                { description &&
                    <p>{description}</p>
                }
                <img src={'/images/divider-2.svg'} className={classes.divider} alt={title}></img>
                { locationName &&
                    <p className={classes.locationName}>{locationName}</p>
                }
                { address &&
                    address.map((addressLine) => (
                        <p>{addressLine}</p>
                    ))
                }
                { url &&
                    <p><a href={url}>{urlLabel}</a></p>
                }
            </div>
        </div>
        </Paper>
    )
}

export default Chapter;