const password = "c2VwdDE5MjAyMA=="

const login = (state = [], action) => {
    switch (action.type) {
      case 'SUBMIT_LOGIN_FORM':
        console.log(action.password);
        const loggedInStatus = (btoa(action.password) === password);
        return {
          ...state,
          loggedIn: loggedInStatus,
        }
      case 'SET_LOGGED_IN_STATUS':
        console.log('vince was here');
        return {
          ...state,
          loggedIn: action.payload
        }
      default:
        return state
    }
  }
export default login