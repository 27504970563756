import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cardConfig from './grid-panel-config';
import HomeCard from './home-card';


const styles = (theme) => ({
  gridBoxContainer: {
    // position: "relative",
    // overflow: "hidden",
  },
  gridBox: {
    // backgroundColor: "#000",
    // border: "1px solid #000",
    height: "20em",
    position: "relative",
    overflow: "hidden",
    // maxHeight: "30em",
    '-webkit-transition': '-webkit-transform .25s ease,opacity .25s ease,-webkit-filter 1s ease,-webkit-filter 1s ease',
    transition: 'transform .25s ease,opacity .25s ease,filter 1s ease,-webkit-filter 1s ease',
    // "&:hover": {
    //     textDecoration: 'underline',
    // },
    // :before
    // "&::before": {
    "&:hover:before": {
      opacity: 0
    },
    "&::before": {
      content: "''",
      display: "block",
      height: "100%",
      left: "0",
      position: "absolute",
      width: "100%",
      '-webkit-transition': 'opacity .5s ease',
      transition: 'opacity .5s ease',
      bottom: 0,
      opacity: '.85',
      zIndex: 2,
    },
    "&:hover:after": {
      backgroundColor: "#9ab4a9",
      content: "''",
      display: "block",
      height: "100%",
      left: "0",
      position: "absolute",
      width: "100%",
      // '-webkit-transition': 'opacity .5s ease',
      // transition: 'opacity .5s ease',
      bottom: 0,
      opacity: '.7',
      zIndex: 2,
    },
    "&:first-child:before": {
      opacity: 0,
      "&:hover": {
        opacity: 0,
      },
    },
    "&:nth-child(6n):before": {
      backgroundColor: theme.palette.text.pantoneEmerald,
    },
    "&:nth-child(6n-1):before": {
      backgroundColor: theme.palette.text.emerald,
    },
    "&:nth-child(6n-2):before": {
      backgroundColor: theme.palette.text.slateBlue,
    },
    "&:nth-child(6n-3):before": {
      backgroundColor: theme.palette.text.summerGreen,
    },
    "&:nth-child(6n-4):before": {
      backgroundColor: theme.palette.text.sage,
    },
    "&:nth-child(6n-5):before": {
      backgroundColor: theme.palette.text.cornflowerBlue,
    },
  },
  cardRoot: {
    height: "100%",
    width: "100%",
  },
  cardTitle: {
    fontSize: 14,
  },
  cardTextSecondary: {
    marginBottom: 12,
  },
});



class HomeGridPanel extends Component {

  createGridCards = (classes) => {
    const cards = cardConfig.map((card, index) => {
      let md = 8;
      if (Math.ceil((index + 1) * 1.5) % 2 === 0) {
        md = 4;
      }
      return (
        <Grid item key={card.title} md={md} xs={12} className={classes.gridBox}>
          <HomeCard config={card} />
        </Grid>
      )
    });
    return cards;

  }

  render() {
    const { classes } = this.props;
    const cards = this.createGridCards(classes);
    return (
      <Grid container className={classes.gridBoxContainer}>
        {cards}
      </Grid>
    )
  }
}

HomeGridPanel.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
};

export default withStyles(styles)(HomeGridPanel);
