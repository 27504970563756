import React from 'react';

export const weddingDate = new Date('2020-09-19T12:00:00');

const Completionist = () => <span>Today's the day!</span>;

const CountdownRender = (props) => {
	return (
    <span>
      {props.days} days to go!
    </span>
)};

export const renderer = (props) => {
  if (props.completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return <CountdownRender {...props} />;
  }
};

export default {}