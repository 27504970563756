import { Box, Grid, Paper } from '@material-ui/core';
import React, { Component } from 'react';
import Confetti from 'react-dom-confetti';
import MainContent from '../../components/main-content/mainContent';

const attending = {
  title: "Thanks!",
  content: "Thank you for attending our wedding. We can’t wait to celebrate with you!"
}

const notAttending = {
  title: "Thank you",
  content: "We’re sorry to miss you. Thank you for your love and support!"
}
const rainbowConfetti = ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
// weddingColorConfetti = ["#009874", "#02582f", "#798b7f", "#9ab4a9", "#8db1c7", "#a1b1b8"]
const confettiConfig = {
  angle: 90,
  spread: "162",
  startVelocity: "50",
  elementCount: "136",
  dragFriction: 0.1,
  duration: "4470",
  delay: 0,
  width: "19px",
  height: "41px",
  colors: rainbowConfetti
};

class RSVPThanks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activateConfetti: false,
      submissionResults: props.submissionResults,
    };
  }


  componentDidMount = () => {
    if (this.state.submissionResults.attending === "accept") {
      setTimeout(() => {
        this.setState({ activateConfetti: true });
      }, 300);
    }
  }

  // let results;
  // Object.keys(this.state.submissionResults).forEach((formItem) => {
  //   results += <span>{formItem} {this.state.submissionResults[formItem]}</span>
  // })
  // const timeout = (ms) => {
  //   return new Promise(resolve => setTimeout(resolve, ms));
  // }
  // this.setState({ isSubmitting: true });
  // timeout(1000)
  // this.setState({ isSubmitting: false });
  render() {
    let mainContent
    if (this.state.submissionResults.attending === "accept") {
      mainContent = attending;
    } else {
      mainContent = notAttending;
    }
    return (
      <Box>
        <MainContent title={mainContent.title} content={mainContent.content} />
        <Paper>
          <Grid container spacing={2} alignContent="center" justify="center" align="center" alignItems="center">
            <Grid item xs={12} align="center" >
              {this.state.submissionResults.formalName}
            </Grid>
            <Grid item xs={12} align="center" >
              Attending: {this.state.submissionResults.attending}
            </Grid>
            {this.state.submissionResults.email &&
              <Grid item xs={12} align="center" >
                Email: {this.state.submissionResults.email}
              </Grid>
            }
            {this.state.submissionResults.partySize > 0 &&
              <Grid item xs={12} align="center" >
                Number Attending: {this.state.submissionResults.partySize}
              </Grid>
            }
            {this.state.submissionResults.guestName &&
              <Grid item xs={12} align="center" >
                Guest Name: {this.state.submissionResults.guestName}
              </Grid>
            }
            {this.state.submissionResults.message &&
              <Grid item xs={12} align="center" >
                Message: {this.state.submissionResults.message}
              </Grid>
            }
            
          </Grid>
        </Paper>
        <Grid container spacing={2} alignContent="center" justify="center" align="center" alignItems="center">
            <Grid item xs={4} align="center" >
              <Confetti active={this.state.activateConfetti} config={confettiConfig} />
            </Grid>
        </Grid>
        {/* {JSON.stringify(this.state.submissionResults)} */}
        
      </Box>
    );
  }
}

export default RSVPThanks;