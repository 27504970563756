import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Footer from '../components/footer/footer';
import Header from '../components/header/header';
import PageHeader from '../components/header/page-header';
import GAListener from './gaListener';

const RouteSwitch = (props) => {
  const { 
    pageRoutes,
    // loggedIn,
   } = props;
  // console.log(loggedIn);
    return (
      <Router>
        <GAListener trackingId="UA-159210025-1">
        <Switch>
        {pageRoutes.filter(page => page.header === false).map(page => (
          <Route 
            exact
            path={page.url}
            key={page.url}
            render={() => null} />
        ))}
          <Route path={'/:route'} render={() => <Header title="Mill and Vince" pageRoutes={pageRoutes} />} />
          <Route path={'/'} render={() => <Header heroText={true} title="Mill and Vince" pageRoutes={pageRoutes} />} />
        </Switch>
        <Switch>
            {pageRoutes.map(page => (
                <Route
                exact
                path={page.url}
                key={page.url}
                // component={page.component}
                render={(routeProps) => {
                  if (props.loggedIn !== true && page.private !== true) { //change to page.public in order to make default private again
                    return <Redirect to='/login' />;
                  } else if (props.loggedIn === true && page.url === '/login') {
                    return <Redirect to='/' />;
                  } else {
                    return (
                      <Box>
                        {page.url !== '/' && page.subheader !== false &&
                          <PageHeader title={page.title} subheader={page.subheader} />
                        }
                        <page.component {...props} />
                      </Box>
                    
                    );
                  }
                }}
                />
            ))}
        </Switch>
        <Switch>
        {pageRoutes.filter(page => page.footer === false).map(page => (
          <Route 
            exact
            path={page.url}
            key={page.url}
            render={() => null} />          
        ))}
          <Route path={'/:route'} render={() => <Footer title="Mill and Vince" description="Logo goes here" />} />
          <Route path={'/'} render={() => <Footer title="Mill and Vince" description="Logo goes here" />} />
        </Switch>
        </GAListener>
    </Router>
        )
};

RouteSwitch.propTypes = {
  pageRoutes: PropTypes.array,
};

const mapStateToProps = state => ({
  // loggedIn: state.login.loggedIn,
  loggedIn: true,
});

export default connect(
  mapStateToProps
)(RouteSwitch);