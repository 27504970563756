import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { weddingDate } from '../../components/countdown/countdown';

const useStyles = makeStyles(theme => ({
    mainFeaturedPost: {
      position: 'relative',
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 30%',
      filter: 'grayscale(100%)',
    },
    overlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,.6)',
    },
    mainFeaturedPostContent: {
      position: 'relative',
      padding: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(6),
        paddingRight: 0,
      },
      minHeight: '500px'
    },
}));

const heroImage = {
  title: weddingDate.toLocaleDateString("en-US"),
  description: "",
  image: '/images/mill-vince-small.jpg',
  imgText: 'main image description',
};

const HomeHeroImage = (props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${heroImage.image})` }}>
      {/* Increase the priority of the hero background image */}
      {<img style={{ display: 'none' }} src={heroImage.image} alt={heroImage.imageText} />}
      <div className={classes.overlay} />
      <Grid container>
        <Grid item md={4}>
          <div className={classes.mainFeaturedPostContent}>
          </div>
        </Grid>
        
      </Grid>
    </Paper>
  );
}

export default HomeHeroImage;