import { Box, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import RSVPForm from './form';
import RSVPThanks from './thank-you';

const styles = (theme) => ({
  paper: {
  }
});

class RSVP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubmitted: false,
      isError: false
    };
  }

  formSubmitted = (submissionResults) => {
    console.log("SUBMITTED");
    this.setState({
      formSubmitted: true,
      submissionResults: submissionResults
    })
  }
  render() {
    //eslint-disable-next-line no-unused-vars
    const { classes } = this.props;
    //eslint-disable-next-line no-unused-vars
    let content;
    if (this.state.formSubmitted === true) {
      content = <RSVPThanks
        formSubmitted={this.state.formSubmitted}
        submissionResults={this.state.submissionResults} />;
    } else {
      content = <RSVPForm formSubmitted={this.formSubmitted} />;
    }
    return (
      <Box>
          {content}
      </Box>
    );
  }
}

export default withRouter(withStyles(styles)(RSVP));