import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  drawerNav: {
    [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    backgroundColor: theme.palette.background.default,
  },
  drawerLink: {
    color: 'inherit',
    textTransform: 'uppercase',
    textDecoration: 'none',
    '$:hover': {
        color: 'inherit'
    },
  },
}));

export default function NavDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    showSideMenu: false
  });

  const toggleDrawer = (open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, showSideMenu: open });
  };

  return (
    <div className={classes.drawerNav}>
      <Button onClick={toggleDrawer(true)}><MenuIcon/> Menu</Button>
      <Drawer open={state.showSideMenu} onClose={toggleDrawer(false)}>
        <div
        className={classes.list}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
        >
        <List>
            {props.navRoutes.map((page, index) => (
                <Link key={page.url} to={page.url} className={classes.drawerLink}>
                    <ListItem button>
                    {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                    <ListItemText primary={page.title} />
                </ListItem>
                </Link>
            ))}
        </List>
        </div>
      </Drawer>
    </div>
  );
}
