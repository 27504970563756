import React from 'react';

class Registry extends React.Component {
  componentDidMount () {
    const script = document.createElement("script");
 
    script.src = "https://widget.zola.com/js/widget.js";
    script.async = true;
 
    document.body.appendChild(script);
  }

  render() {
    return (
      <React.Fragment>
        <a className="zola-registry-embed" href="www.zola.com/registry/millandvince" data-registry-key="millandvince">Our Zola Wedding Registry</a>
      </React.Fragment>
    )
  }
}

export default Registry;