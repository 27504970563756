const MARKERS = [
  {
    name: "Fairfield Inn & Suites",
    type: "accommodation",
    rate: "$219-$239 per night",
    details: "Limited rooms available; reference the Scott-Montalbano Wedding",
    address: "12 Ridgeview Lane, Plymouth NH",
    phone: "(603) 536-0100",
    url: "https://www.marriott.com/hotels/travel/lcipl-fairfield-inn-and-suites-plymouth-white-mountains/",
    latitude: 43.772100,
    longitude: -71.721820,
  },
  {
    name: "Quality Inn",
    type: "accommodation",
    rate: "$189 per night",
    details: "Limited rooms available; reference the Scott-Montalbano Wedding",
    address: "53 West St, Ashland NH",
    phone: "(603) 968-7668",
    url: "https://www.choicehotels.com/new-hampshire/ashland/quality-inn-hotels/nh022?source=gyxt",
    latitude: 43.700348,
    longitude: -71.646096,   
  },
  {
    name: "Days Inn",
    type: "accommodation",
    rate: "$140 per night",
    details: "Reference the Scott-Montalbano Wedding",
    address: "1513 DW Hwy, Campton NH",
    phone: "(603) 536-3520",
    url: "https://www.wyndhamhotels.com/days-inn/campton-new-hampshire/days-inn-campton/overview",
    latitude: 43.953800,
    longitude: -71.680860,
  },
  {
  name: "Colonel Spencer Inn",
  type: "accommodation",
  rate: "$317-$378 per night",
  details: "All 6 rooms reserved; reference the Scott-Montalbano Wedding",
  phone: "​(603) 536-1755",
  address: "3 Colonel Spencer Rd, Campton, NH 03223",
  url: "https://www.colonelspencerbb.com/",
  latitude: 43.801910,
  longitude: -71.674520,
},
{
  name: "The Blue Moon Motel",
  type: "accommodation",
  rate: "$298-$428 for two nights (the minimum required)",
  details: "Reference the Scott-Montalbano Wedding",
  address: "435 Mayhew Tpk, Hebron NH",
  phone: "(603) 744-2433",
  url: "http://www.thebluemoonmotel.com/",
  latitude: 43.675643,
  longitude: -71.757620,
},
// {
//   name: "Coppertoppe Inn and Retreat Center",
//   type: "accommodation",
//   rate: "$189-$295 per night",
//   details: "All 4 rooms reserved; reference the Scott-Montalbano Wedding",
//   address: "8 Range Rd, Hebron NH",
//   phone: "(603) 744-3636",
//   latitude: 43.706775,
//   longitude: -71.781273,
// },
// {
//   name: "Pleasant View Bed & Breakfast",
//   type: "accommodation",
//   rate: "$155-$175 per night",
//   details: "All six rooms reserved; reference the Scott-Montalbano Wedding",
//   address: "22 Hemp Hill Rd, Bristol, NH 03222",
//   phone: "(603) 744-5547",
//   url: "https://www.staynh.org/inn/pleasant-view",
//   latitude: 43.625952,
//   longitude: -71.768039,
// },
{
  name: "Bridgewater Inn & Motel",
  type: "accommodation",
  rate: "$119-$139 per night",
  details: "Limited rooms reserved; reference the Scott-Montalbano Wedding",
  address: "367 Mayhew Turnpike Bridgewater NH",
  url: "https://www.bridgewater-inn.com/index.html",
  phone: "​(603) 744-3518",
  latitude: 43.643838,
  longitude: -71.735952,
},
{
  name: "The Inn on Golden Pond",
  type: "accommodation",
  rate: "$230-$275 per night, two night minimum",
  details: "9 rooms available, first come, first served. Cancellation policy: 14 day prior notice required and $30 booking fee ",
  address: "1080 U.S. Route 3, Holderness, NH",
  phone: "​(603) 968-7269",
  url: "http://innongoldenpond.com/",
  latitude: 43.725680,
  longitude: -71.605350,
},
{
  name: 'wedding',
  type: "important-marker",
  image: '/images/rings.svg',
  details: 'Wedding at Our Lady of Grace Church',
  address: '17 W Shore Rd, Bristol, NH 03222',
  latitude: 43.616830,
  longitude: -71.737520,
},
{
  name: 'reception',
  type: "important-marker",
  image: '/images/reception.svg',
  details: 'Reception at Meadow Wind',
  address: '41 N. Shore Rd. Hebron, NH, 03241',
  latitude: 43.693750,
  longitude: -71.804140,
},
];

export default MARKERS;