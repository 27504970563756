import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import * as React from 'react';
import TRAVEL_DETAILS from './travel-details';

const styles = (theme) => ({
  travelCard: {
    minHeight: '120px',
    margin: '10px',
    textAlign: 'left',
    padding: '10px',
    fontWeight: 300,
  },
  travelSectionTitle: {
    paddingLeft: '10px',
    textAlign: 'center',
    paddingBottom: '20px',
  },
  cardTitle: {
    textTransform: 'uppercase',
    fontFamily: 'Antic Didone',
  },
  travelIconFly: {
    width: '40px',
    textAlign: 'center'
  },
  travelIconDrive: {
    width: '60px',
    textAlign: 'center'
  },
  travelSectionHeader: {
    textAlign: 'center',
    minHeight: '70px',
  }
});

class Travel extends React.Component {

  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
      <Grid item xs={12} className={classes.travelSectionHeader}>
        <img alt='' src="/images/fly.svg" className={classes.travelIconFly} />
      </Grid>
      <Typography variant="h6" className={classes.travelSectionTitle} gutterBottom>Flights</Typography>
        {TRAVEL_DETAILS["fly"].map((airports) => (
          <React.Fragment>
            <Paper elevation={4} className={classes.travelCard}>
            <Typography variant="subtitle2" gutterBottom className={classes.cardTitle}>{airports.name} ({airports.code})</Typography>
            <Grid item xs={12}>{airports.address}</Grid>
            <Grid item xs={12}><a href={airports.url}>Website</a></Grid>
            <Grid item xs={12}>{airports.travelTime}</Grid>
            </Paper>
          </React.Fragment>
        ))}
      </Grid>
      <Grid item xs={12} sm={6}>
      <Grid item xs={12} className={classes.travelSectionHeader}>
        <img alt='' src="/images/drive.svg" className={classes.travelIconDrive} />
      </Grid>
      <Typography variant="h6" className={classes.travelSectionTitle} gutterBottom>Driving</Typography>
      {TRAVEL_DETAILS["drive"].map((origin) => (
          <React.Fragment>
            
            <Paper elevation={4} className={classes.travelCard}>
            <Typography variant="subtitle2" gutterBottom className={classes.cardTitle}>{origin.name}</Typography>
            {origin.directionSteps.map((step) => (
              <Grid item xs={12}>{step}</Grid>
            ))}
            </Paper>
          </React.Fragment>
        ))}
      </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Travel);