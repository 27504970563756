import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
    inkBranch: {
      maxWidth: '40px'
    },
    gridContainer: {
      backgroundColor: theme.palette.background.default,
    }
    
}));

const SmallHeaderText = (props) => {
    const classes = useStyles();

    return (
      <Grid container justify={"center"} className={classes.gridContainer}>

      
      <Grid item xs={12} align="center">
      <Typography component="h2" variant="h3" color="inherit" gutterBottom>
        Mill <img src='/images/manorah.svg' alt={"and"} className={classes.inkBranch} /> Vince
        </Typography>
      </Grid>
      </Grid>
        )
};
SmallHeaderText.propTypes = {
};
export default SmallHeaderText;