export const guestList = [
{informalName: "Ward II and Patrice Scott", formalName: "Colonel and Mrs. Ward Ellis Scott, II, USMC (Ret)", maxGuests: 2},
{informalName: "Mike and Sue Montalbano", formalName: "Mr. and Mrs. Michael Montalbano", maxGuests: 2},
{informalName: "Tom and Emily Montalbano", formalName: "Captain and Mrs. Thomas Montalbano, USMC", maxGuests: 2},
{informalName: "Dom and Angela and Family", formalName: "Captain and Mrs. Dominic Montalbano, USMC ", maxGuests: 2},
{informalName: "Rebecca Scott and Guest", formalName: "Ms. Rebecca Scott and Guest", maxGuests: 2},
{informalName: "Carlo and Ashley Scott", formalName: "Major and Mrs. Carl-Werner Scott, USMCR", maxGuests: 2},
{informalName: "Helena Scott and Guest", formalName: "Ms. Helena Scott and Guest", maxGuests: 2},
{informalName: "Sander Scott and RJ Stidd", formalName: "Lieutenant Alexander Scott, USN and Ms. Ralda Stidd", maxGuests: 2},
{informalName: "Bubba and Meghan Scott", formalName: "Captain and Mrs. Ward Ellis Scott, III, USMC", maxGuests: 2},
{informalName: "Noodle Scott and Guest", formalName: "Lieutenant Junior Grade Veronica Scott, USN and Guest", maxGuests: 2},
{informalName: "Laura Schillinger", formalName: "Ms. Laura Schillinger", maxGuests: 1},
{informalName: "Ed and Mary Jo Schillinger", formalName: "Mr. and Mrs. Edwin Schillinger, II", maxGuests: 2},
{informalName: "Eddie and Jamie Schillinger", formalName: "Mr. and Mrs. Edwin Schillinger, III", maxGuests: 2},
{informalName: "Michele Montalbano", formalName: "Mrs. Michele Montalbano", maxGuests: 1},
{informalName: "Jim and Danielle Montalbano", formalName: "Mr. and Mrs. James Montalbano", maxGuests: 2},
{informalName: "John and Jess Montalbano", formalName: "Mr. and Mrs. John Montalbano", maxGuests: 2},
{informalName: "Tom Moffat", formalName: "Mr. Tom Moffat", maxGuests: 1},
{informalName: "Charles Montalbano and Guest", formalName: "Mr. Charles Montalbano and Guest", maxGuests: 2},
{informalName: "Shane and Katy Mitchell", formalName: "Mr. and Mrs. Shane Mitchell", maxGuests: 2},
{informalName: "Jim and Gail Maguire", formalName: "Mr. and Mrs. James Maguire, III", maxGuests: 2},
{informalName: "John Trumbo and Helen-Marie Maguire-Trumbo", formalName: "Doctor John Trumbo and Ms. Helen-Marie Maguire-Trumbo", maxGuests: 2},
{informalName: "Boris Picque and Cassie Capano", formalName: "Ms. Cassandra Capano and Mr. Boris Picque", maxGuests: 2},
{informalName: "Veronica and Jeff Bernicke", formalName: "Mr. and Mrs. Jeffrey Bernicke", maxGuests: 2},
{informalName: "Nan Dillon", formalName: "Mrs. Anne Dillon", maxGuests: 1},
{informalName: "Brian and Marie Maguire", formalName: "Mr. and Mrs. Brian Maguire", maxGuests: 2},
{informalName: "Matt and Jamie Misley", formalName: "Mr. and Mrs. Matthew Misley", maxGuests: 2},
{informalName: "Kyle and Kim Gallagher", formalName: "Mr. and Mrs. Kyle Gallagher", maxGuests: 2},
{informalName: "Jenn Misley and Guest", formalName: "Ms. Jennifer Misley and Guest", maxGuests: 2},
{informalName: "Father Leo Leblanc", formalName: "Father Leo Leblanc", maxGuests: 1},
{informalName: "Father Tobias and Rose Nyatsambo", formalName: "The Reverend and Mrs. Tobias Nyatsambo", maxGuests: 2},
{informalName: "Lisa Maguire and Guest", formalName: "Ms. Lisa Maguire and Guest", maxGuests: 2},
{informalName: "David and Nancy Heaberlin", formalName: "Mr. and Mrs. David Heaberlin", maxGuests: 2},
{informalName: "Chris and Stephanie Skinner", formalName: "Mr. and Mrs. Chris Skinner", maxGuests: 2},
{informalName: "Jeff and Michelle Schmitt", formalName: "Mr. and Mrs. Jeff Schmitt", maxGuests: 2},
{informalName: "Bill and Jane Schmitt", formalName: "Mr. and Mrs. Bill Schmitt", maxGuests: 2},
{informalName: "Tim Games", formalName: "Mr. Timothy Games ", maxGuests: 1},
{informalName: "Alison and John Curtis", formalName: "Mr. and Mrs. John Curtis", maxGuests: 2},
{informalName: "Michael and Margaret Curtis", formalName: "Doctors Michael and Margaret Curtis", maxGuests: 2},
{informalName: "Jeffrey and Juliane Scott and Family", formalName: "Herr und Frau Jeffrey Scott und Famile", maxGuests: 2},
{informalName: "Ceci and Robert Nobel", formalName: "Mr. and Mrs. Robert Nobel", maxGuests: 2},
{informalName: "Charlotte and Will Iselin", formalName: "Mr. and Mrs. William Iselin", maxGuests: 2},
{informalName: "Alison Games", formalName: "Doctor Alison Games", maxGuests: 1},
{informalName: "Betsy Games", formalName: "Mrs. Elizabeth Games", maxGuests: 1},
{informalName: "Bernhard Clemm von Hohenberg", formalName: "Mr. Bernhard Clemm von Hohenberg c/o von Wahl/Behr", maxGuests: 1},
{informalName: "Christian and Katharina Clemm von Hohenberg", formalName: "Doctors Christian and Katharina Clemm von Hohenberg", maxGuests: 2},
{informalName: "Vincent and Vicki Clemm von Hohenberg", formalName: "Mr. and Mrs. Vincent Clemm von Hohenberg", maxGuests: 2},
{informalName: "Stefanie von Clemm", formalName: "Ms. Stefanie von Clemm", maxGuests: 1},
{informalName: "Mike and Abbie Dausen", formalName: "Lieutenant Commander and Mrs. Michael Dausen, SC, USN", maxGuests: 2},
{informalName: "Greg and Louisa Gehring", formalName: "Mr. and Mrs. Gregory Gehring", maxGuests: 2},
{informalName: "Frank and Caelyn Furman", formalName: "Mr. and Mrs. Frank Furman", maxGuests: 2},
{informalName: "Ryan and Leah Kelly", formalName: "Lieutenant Commander and Mrs. Ryan Kelly, USN", maxGuests: 2},
{informalName: "Doug and Roseann Furbush", formalName: "Mr. and Mrs. Doug Furbush", maxGuests: 2},
{informalName: "Carly Furbush", formalName: "Ms. Carly Furbush", maxGuests: 1},
{informalName: "Dave and Joan Fischer", formalName: "Mr. and Mrs. Dave Fischer", maxGuests: 2},
{informalName: "Amit and Chiara Mitra", formalName: "Mr. and Mrs. Amit Mitra", maxGuests: 2},
{informalName: "Kevin and Patty Boyle", formalName: "Mr. and Mrs. Kevin Boyle", maxGuests: 2},
{informalName: "Rick and Kathy Kollsmith", formalName: "Mr. and Mrs. Rick Kollsmith", maxGuests: 2},
{informalName: "Vince and Kathy Berta", formalName: "Mr. and Mrs. Vince Berta", maxGuests: 2},
{informalName: "Sean and Fern Clough", formalName: "Mr. and Mrs. Sean Clough", maxGuests: 2},
{informalName: "Tom and Jodi Harris", formalName: "Mr. and Mrs. Tom Harris", maxGuests: 2},
{informalName: "John Trifone and Anne Foster", formalName: "Mr. John Trifone and Mrs. Anne Foster", maxGuests: 2},
{informalName: "Rich and Cathy Skotowski", formalName: "Doctor and Mrs. Rich Skotowski", maxGuests: 2},
{informalName: "Mr and Mrs Manganiello", formalName: "Mr. and Mrs. Paul Manganiello", maxGuests: 2},
{informalName: "Chelsea and Zach Huitink", formalName: "Mr. and Mrs. Zachary Huitink", maxGuests: 2},
{informalName: "Eileen Shay and Guest", formalName: "Ms. Eileen Shay and Guest", maxGuests: 2},
{informalName: "Mary and Sarah Sherill", formalName: "Mrs. Mary Sherrill and Ms. Sarah Sherrill", maxGuests: 2},
{informalName: "Karen and Matt Charatan", formalName: "Mr. and Mrs. Matt Charatan", maxGuests: 2},
{informalName: "Jim and Beth Kelly", formalName: "Commander and Mrs. James Kelly, USN (Ret)", maxGuests: 2},
{informalName: "Butch and Darcy Cushing", formalName: "Mr. and Mrs. Wallace Cushing, III and Family", maxGuests: 2},
{informalName: "Liz Donoghue and Woody Edelman and Family", formalName: "Mr. Edward Edelman and Mrs. Elizabeth Donoghue and Family", maxGuests: 2},
{informalName: "Joe and Denise Bugay", formalName: "Mr. and Mrs. Joseph Bugay", maxGuests: 2},
{informalName: "Gary and Kathy Letendre", formalName: "Mr. and Mrs. Gary Letendre", maxGuests: 2},
{informalName: "Sue Kenny and Tom Trotta", formalName: "Mrs. Sue Kenney and Mr. Tom Trotta", maxGuests: 2},
{informalName: "Sandy and Rod Hersh", formalName: "Mr. and Mrs. Rodney Hersh", maxGuests: 2},
{informalName: "Amy Balestieri", formalName: "Ms. Amy Balestieri", maxGuests: 1},
{informalName: "Sarah and JT McDonald", formalName: "Mr. and Mrs. JT McDonald", maxGuests: 2},
{informalName: "Greg and Sarah Dillaway", formalName: "Mr. and Mrs. Greg Dillaway", maxGuests: 2},
{informalName: "Richard and Michelle Zeytoonjian", formalName: "Mr. and Mrs. Richard Zeytoonjian", maxGuests: 2},
{informalName: "Greg and Lisa Kasprzak", formalName: "Mr. and Mrs. Greg Kasprzak", maxGuests: 2},
{informalName: "Stu and Sue Williams", formalName: "Mr. and Mrs. Stu Williams", maxGuests: 2},
{informalName: "Conor and Allyson Keating", formalName: "Captain and Mrs. Conor Keating, USMC", maxGuests: 2},
{informalName: "Mr and Mrs Abbott", formalName: "Ms. Lizabeth Melena and Mr. Bruce Abbott", maxGuests: 2},
{informalName: "Lily and Al Sattinger", formalName: "Alphonse and Lily Sattinger and Ms. Diane Townsend", maxGuests: 2},
{informalName: "Bobby and Cathy Kilmartin", formalName: "Colonel and Mrs. Robert Kilmartin, USMCR (Ret)", maxGuests: 2},
{informalName: "Gerard and Barbara Boyle", formalName: "The Honorable and Mrs. Gerard J. Boyle", maxGuests: 2},
{informalName: "Albert and Merle Manwaring", formalName: "Lieutenant Colonel and Mrs. Albert Manwaring, IV, USAR (Ret)", maxGuests: 2},
{informalName: "Mrs. Messinger", formalName: "Mrs. Susan Messinger", maxGuests: 1},
{informalName: "Walt and MJ Koenig", formalName: "Mr. and Mrs. Walt Koenig", maxGuests: 1},
{informalName: "Bill and Jean Varian", formalName: "Colonel and Mrs. William Varian, ARNG (Ret.)", maxGuests: 2},
{informalName: "Ed and Lynn Wilson", formalName: "Mr. and Mrs. D. Edward Wilson, Jr.", maxGuests: 2},
{informalName: "Kira and David Mikulecky", formalName: "Mr. and Mrs. David Mikulecky", maxGuests: 2},
{informalName: "Nina Mishkin and Guest", formalName: "Ms. Nina Mishkin and Guest", maxGuests: 2},
{informalName: "Hilary Robinson and IS Dunklin", formalName: "Ms. Hilary Robinson and Mr. IS Dunklin", maxGuests: 2},
{informalName: "Kit and Tom Schaeffer", formalName: "Mr. and Mrs. Tom Schaeffer", maxGuests: 2},
{informalName: "Nigel and Sarah Martin", formalName: "Mr. and Mrs. Nigel Martin", maxGuests: 2},
{informalName: "Matt and Silvana Woodrome", formalName: "Mr. and Mrs. Matthew Woodrome", maxGuests: 2},
{informalName: "Peter Teneriello ", formalName: "Mr. Peter Teneriello ", maxGuests: 1},
{informalName: "Thom Coupe and Georgie Perry", formalName: "Mr. Thomas Coupe and Ms. Georgie Perry", maxGuests: 2},
{informalName: "Ed Munch", formalName: "Mr. Edward Munch", maxGuests: 1},
{informalName: "Jane Christovao and Guest", formalName: "Ms. Jane Christovao and Guest", maxGuests: 2},
{informalName: "Catriona Jones and Julien Moore", formalName: "Ms. Catriona Jones and Mr. Julian Moore", maxGuests: 2},
{informalName: "Bryan and Lauren Lennon", formalName: "Mr. and Mrs. Bryan Lennon", maxGuests: 2},
{informalName: "Sam and Hillary Hairston", formalName: "Mr. and Mrs. Samuel Hairston", maxGuests: 2},
{informalName: "Amie White Colen", formalName: "Ms. Amie Colen", maxGuests: 1},
{informalName: "Anne Christensen and Guest", formalName: "Ms. Anne Christensen and Guest", maxGuests: 2},
{informalName: "Eric and Obrien Carlson", formalName: "Mr. and Mrs. Eric Carlson", maxGuests: 2},
{informalName: "Rory and Eileen Banks", formalName: "Mr. and Mrs. Rory Banks", maxGuests: 2},
{informalName: "Jamie and Amy Kocher", formalName: "Mr. and Mrs. James Kocher", maxGuests: 2},
{informalName: "Mike and Dana Arnold", formalName: "Mr. and Mrs. Michael Arnold", maxGuests: 2},
{informalName: "Brian and MK O'Boyle", formalName: "Mr. and Mrs. Brian O'Boyle", maxGuests: 2},
{informalName: "Sam and Jimmy Hawthorne", formalName: "Mr. and Mrs. James Hawthorne", maxGuests: 2},
{informalName: "Alex Jenal and Greg Jones", formalName: "Mrs. Alex Jenal and Mr. Greg Jones", maxGuests: 2},
{informalName: "Matt and Lindsay Demczko", formalName: "Doctor and Mrs. Matthew Demczko", maxGuests: 2},
{informalName: "Crosby and Dinah Cook", formalName: "Mr. and Mrs. Crosby Cook", maxGuests: 2},
{informalName: "George and Christina Razook", formalName: "Mr. and Mrs. George Razook", maxGuests: 2},
{informalName: "Joe and Vaughan Stewart", formalName: "Mr. and Mrs. Joe Stewart", maxGuests: 2},
{informalName: "Clelia Zacharias and Guest", formalName: "Ms. Clelia Zacharias and Guest", maxGuests: 2},
{informalName: "Binny and Ben Rubeor", formalName: "Mr. and Mrs. Benjamin Rubeor", maxGuests: 2},
{informalName: "Louise Denny and Kevin Considine", formalName: "Mrs. Louise Denny and Mr. Kevin Considine", maxGuests: 2},
{informalName: "Alexis Rathborne and Julie", formalName: "Mrs. Alexis Rathborne and Mrs. Julie Bennett", maxGuests: 2},
{informalName: "Tess Russell ", formalName: "Ms. Tess Russell ", maxGuests: 1},
{informalName: "Mrs. Hannah Hickok and Mr. Jonathan Sadighian", formalName: "Mrs. Hannah Hickok and Mr. Jonathan Sadighian", maxGuests: 2},
{informalName: "Aaron Hans", formalName: "Mr. Aaron Hans", maxGuests: 1},
{informalName: "Sarah Weiler ", formalName: "Ms. Sarah Weiler", maxGuests: 1},
{informalName: "Hugh and Rachel McDermott", formalName: "Doctor and Mrs. Hugh McDermott", maxGuests: 2},
{informalName: "Colin and Catie Raymond", formalName: "Mr. and Mrs. Colin Raymond", maxGuests: 2},
{informalName: "Ryan and Sam Degnan", formalName: "Mr. and Mrs. Ryan Degnan", maxGuests: 2},
{informalName: "Matt Lynch", formalName: "Mr. Matthew Lynch", maxGuests: 1},
{informalName: "Kyle Tenant", formalName: "Father Kyle Tenant", maxGuests: 1},
{informalName: "Erica Palag", formalName: "Ms. Erica Palag", maxGuests: 1},
{informalName: "Nick and Kailley Federighi", formalName: "Mr. and Mrs. Nicholas Federighi", maxGuests: 2},
{informalName: "Casey and Katie Cockerham", formalName: "Mr. and Mrs. Casey Cockerham", maxGuests: 2},
{informalName: "Deanna Munoz and Patrick Montalvo", formalName: "Ms. Deanna Munoz and Mr. Patrick Montalvo", maxGuests: 2},
{informalName: "Andy Stulc ", formalName: "Mr. Andrew Stulc ", maxGuests: 2},
{informalName: "Mike Matheson and Nisreen Hasib", formalName: "Mr. Michael Matheson and Ms. Nisreen Hasib", maxGuests: 2},
{informalName: "Dillon and Linda Bailey", formalName: "Mr. and Mrs. Dillon Bailey", maxGuests: 2},
{informalName: "Emily Meyer and Guest", formalName: "Ms. Emily Meyer and Guest", maxGuests: 2},
{informalName: "Franco and Laura Zarama", formalName: "Mr. and Mrs. Franco Zarama", maxGuests: 2},
{informalName: "Tony and Caitlin Ayala", formalName: "Mr. and Mrs. Tony Ayala", maxGuests: 2},
{informalName: "Tomas and Carolyn Gallegos", formalName: "Mr. and Mrs. Tomas Gallegos", maxGuests: 2},
{informalName: "Nate Anderson and Laura Kenney", formalName: "Mr. Nathan Anderson and Ms. Laura Kenney", maxGuests: 2},
{informalName: "Ajay Karwa and Cathleen Clark", formalName: "Mrs. Cathleen Clark and Mr. Ajay Karwa", maxGuests: 2},
{informalName: "Kaylan and Jeff Alderson", formalName: "Mr. and Mrs. Jeffrey Alderson", maxGuests: 2},
{informalName: "Ashley Dow and Chris Feeney", formalName: "Ms. Ashley Dow and Mr. Chris Feeney ", maxGuests: 2},
{informalName: "Anna Lockwood and Ryan Kelly", formalName: "Mr. and Mrs. Ryan Kelly", maxGuests: 2},
{informalName: "Bri and Joe Lambert", formalName: "Mr. and Mrs. Joseph Lambert", maxGuests: 2},
{informalName: "Rudy Hersh and Lizzie Manganiello", formalName: "Mr. Rudolph Hersh and Ms. Elizabeth Manganiello", maxGuests: 2},
{informalName: "Steve and Michelle Thompson", formalName: "Mr. and Mrs. Steven Thompson", maxGuests: 2},
{informalName: "Randy and Jordan Babb", formalName: "Mr. and Mrs. Randy Babb", maxGuests: 2},
{informalName: "Rory Carrol and Rachel Carrol", formalName: "Doctor and Mrs. Rory Carrol", maxGuests: 2},
{informalName: "Rabbi Tom Pesch and Guest", formalName: "Mr. Tom Pesch and Guest", maxGuests: 2},
{informalName: "Missy Burgin and Tim Peck", formalName: "Ms. Melissa Burgin and Mr. Timothy Peck", maxGuests: 2},
{informalName: "Chris and Blaine Heck", formalName: "Mr. and Mrs. Christopher Heck", maxGuests: 2},
{informalName: "Deborah Westerman", formalName: "Ms. Deborah Westerman", maxGuests: 1},
{informalName: "Oliver Libuda and Guest", formalName: "Mr. Oliver Libuda and Guest", maxGuests: 2},
{informalName: "Luke Purcell and Clair Maurice", formalName: "Mr. Luke Purcell and Mrs. Clair Maurice ", maxGuests: 2},
{informalName: "Jai and Bijal Gandecha", formalName: "Mr. and Mrs. Jai Gandecha", maxGuests: 2},
{informalName: "Matt Bruno and Guest", formalName: "Mr. Matthew Bruno and Guest", maxGuests: 2},
{informalName: "Carleigh Connelly and Chris Rochester", formalName: "Mrs. Carleigh Connelly and Mr. Chris Rochester", maxGuests: 2},
{informalName: "Owen Stretch and Guest", formalName: "Mr. Owen Stretch and Guest", maxGuests: 2},
{informalName: "Cecil Alleyne and Guest", formalName: "Mr. Cecil Alleyne and Guest", maxGuests: 2},
{informalName: "Litty and Sean Flynn", formalName: "Mr. and Mrs. Sean Flynn", maxGuests: 2},
{informalName: "Anita Nixon", formalName: "Ms. Anita Nixon", maxGuests: 1},
{informalName: "Lekan Oladeji and Laura Oladeji Deangelis ", formalName: "Mrs. Laura Oladeji Deangelis and Mr. Lekan Oladeji", maxGuests: 2},
{informalName: "Rafael and Aline Caixeta", formalName: "Mr. and Mrs. Rafael Caixeta", maxGuests: 2},
{informalName: "Renato and Fernanda Gondo", formalName: "Mr. and Mrs. Renato Gondo", maxGuests: 2},
{informalName: "Jim Condos and Guest", formalName: "Mr. James Condos and Guest", maxGuests: 2},
];