import { Button, FormControl, FormControlLabel, FormGroup, FormHelperText, NativeSelect, Radio, RadioGroup, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import React, { Component } from 'react';
import { guestList } from './guest-list';


const styles = (theme) => ({
    rsvpForm: {
        // borderColor: theme.palette.text.emerald,
        // "&:hover": {
        //     borderColor: theme.palette.text.emerald,
        // },
        // '&$focused': {
        //     borderColor: theme.palette.text.emerald,
        // },
    },
    formRows: {
        marginBottom: 10,
        textTransform: 'uppercase',
        fontWeight: '300', // TODO: this font weight is not working
    },
    error: {
        fontWeight: "bold",
        color: "red",
    },
    groupBorder: {
        border: `1px solid rgba(0, 69, 0, 0.23)`,
        borderRadius: '4px',
        padding: '0 10px',
        // borderColor: theme.palette.text.emerald,
        // "&:hover": {
        //     borderColor: theme.palette.text.emerald,
        // },
        // '&$focused': {
        //     borderColor: theme.palette.text.emerald,
        // },
    },
    textField: {
                // border: `1px solid rgba(0, 69, 0, 0.23)`,
                // borderRadius: '4px',
                // padding: '0 10px',
                // borderColor: theme.palette.text.emerald,
                // "&:hover": {
                //     borderColor: theme.palette.text.emerald,
                // },
                // '&$focused': {
                //     borderColor: theme.palette.text.emerald,
                // },
                // borderColor: 'green',
        // borderWidth: 2,
        // '& input + fieldset': {
        //     borderColor: 'green',
        //     // borderWidth: 2,
        // },
        font: theme.typography.fontFamily,
        // color: theme.
    },
    radioGroupFieldset: {
        width: '100%'
    }
});

class RSVPForm extends Component {
    constructor(props) {
        super(props);
        let isLocal = false
        if (window.location.href.indexOf("localhost") > -1) {
            isLocal = true;
        }
        this.state = {
            values: {
                informalName: "",
                formalName: "",
                email: "",
                partySize: 1,
                maxGuests: 1,
                message: "",
                guestName: "",
                attending: null,
            },
            isLocal: isLocal,
            isSubmitting: false,
            isError: false
        };
    }

    filterOptions = createFilterOptions({
        // matchFrom: 'any',
        // stringify: option => option.formalName,
        limit: 10,
    });
    timeout = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    onAutocompleteChange = (propName, value) => {
        // console.log(value);
        // debugger;
        if (value === null) {
            this.setState({
                values: {
                    ...this.state.values,
                    informalName: "",
                    formalName: "",
                    partySize: null,
                    maxGuests: 1,
                }
            });
        } else if (value) {
            this.setState({
                values: {
                    ...this.state.values,
                    ...value,
                    partySize: value.maxGuests
                }
            });
        }
    }

    onSelectChange = name => event => {
        console.log(event.target.value);
        console.log(name)
        this.setState({
            values: {
                ...this.state.values,
                [name]: event.target.value,
            }
        });
    };

    onChange = (name) => (e) => {
        /*
          Because we named the inputs to match their
          corresponding values in state, it's
          super easy to update the state
        */
        //  console.log(e);
        //   debugger;
        this.setState({
            values: {
                ...this.state.values,
                [name]: e.target.value
            }
        });
        if (name === "attending" && e.target.value === "decline") {
            this.setState({
                values: {
                    ...this.state.values,
                    partySize: 0,
                    [name]: e.target.value,
                }
            });
        } else if (name === "attending" && e.target.value === "accept") {
            this.setState({
                values: {
                    ...this.state.values,
                    partySize: this.state.values.maxGuests,
                    [name]: e.target.value,
                }
            });
        }
    }

    processResponse = (data) => {
        this.setState({ responseData: data, isSubmitting: false });
        this.props.formSubmitted(this.state.values);
    }

    submitForm = async e => {
        e.preventDefault();
        console.log(this.state);
        this.setState({
            isSubmitting: true,
            isError: false,
            message: "",
        });
        // const { history } = this.props;
        let data;

        if (this.state.values.attending !== "accept" && this.state.values.attending !== "decline") {
            this.setState({ isSubmitting: false });
            this.setState({ message: "Please select whether you can attend.", isError: true });
            return;
        }

        if (this.state.isLocal === true) {
            await this.timeout(1000)
            this.setState({ isSubmitting: false });
            data = {
                isError: false
            }
            this.processResponse(data);
        } else {
            const url = "/.netlify/functions/rsvp"
            fetch(url, {
                method: "POST",
                body: JSON.stringify(this.state.values),
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error('Something went wrong :( please try again or email your RSVP to vince.montalbano@gmail.com. Sorry about that!');
                    }
                })
                .then(data => {
                    this.processResponse(data);
                })
                .catch(error => this.setState({ message: error.message, isError: true, isSubmitting: false }));
        }

    };
    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Grid container spacing={0} justify="center" alignItems="center">
                    <Grid container spacing={0} justify="center" >
                        {/* <Paper className={classes.paper}> */}
                        <Grid item xs={10} sm={8} md={4} lg={3}>
                            <form onSubmit={this.submitForm}>
                                <input type="hidden" name="form-name" value="rsvp" />
                                {/* <Grid item xs={12} sm={8} className={classes.formRows}> */}
                                <FormGroup row className={classes.formRows}>
                                    <Autocomplete
                                        id="name"
                                        options={guestList}
                                        filterOptions={this.filterOptions}
                                        getOptionLabel={option => option.formalName}
                                        disableOpenOnFocus={true}
                                        style={{ width: '100%' }}
                                        // onChange={(event, value) => console.log(value)}
                                        onChange={(event, value) => this.onAutocompleteChange("name", value)}
                                        renderInput={params => (
                                            <TextField {...params} required={true} name="name" label="Your Name" variant="outlined" fullWidth={true} className={classes.textField} />
                                        )}
                                    />
                                </FormGroup>
                                {/* </Grid> */}
                                {/* <Grid item xs={12} sm={8} className={classes.formRows}> */}
                                <FormGroup row className={classes.formRows}>
                                    {/* <FormControlLabel labelPlacement="start" value="email" control={ */}
                                    <TextField id="email" label="Email (Optional)" variant="outlined" fullWidth={true} value={this.state.values.email} onChange={this.onChange("email")} className={classes.textField} />
                                    {/* } label="Email" /> */}
                                </FormGroup>
                                {/* </Grid> */}
                                {/* <Grid item xs={12} sm={8} className={classes.formRows}> */}
                                <FormGroup row className={classes.formRows}>
                                    <FormControl component="fieldset" className={classes.radioGroupFieldset} >
                                        {/* <FormLabel component="legend">Attending</FormLabel> */}
                                        <RadioGroup aria-label="attending" name="attending" 
                                            value={this.state.values.attending}
                                            onChange={this.onChange("attending")}
                                            className={classes.groupBorder} >
                                            <FormControlLabel value="accept" control={<Radio />} label="Accepts with Pleasure" />
                                            <FormControlLabel value="decline" control={<Radio />} label="Declines with Regret" />
                                        </RadioGroup>
                                    </FormControl>
                                </FormGroup>
                                {/* </Grid> */}
                                {this.state.values.attending !== "decline" &&
                                    <FormGroup row className={`${classes.formRows} ${classes.groupBorder}`}>
                                        <FormControl className={classes.radioGroupFieldset}>
                                            <NativeSelect
                                                native="true"
                                                // autoWidth={true}
                                                // labelWidth={100}
                                                value={this.state.values.partySize}
                                                onChange={this.onSelectChange('partySize')}
                                                inputProps={{
                                                    name: 'partySize',
                                                    id: 'partySize',
                                                }}
                                            >
                                                {[...Array(this.state.values.maxGuests)].map((_, i) =>
                                                    <option key={i + 1} value={i + 1} >{i + 1}</option>
                                                )
                                                }
                                            </NativeSelect>
                                            <FormHelperText>Total Guests Attending</FormHelperText>
                                        </FormControl>
                                    </FormGroup>
                                }
                                {((this.state.values.formalName &&
                                    this.state.values.formalName.indexOf("Guest") > 0 &&
                                    this.state.values.partySize > 1 && this.state.values.attending === "accept") || (this.state.values.formalName.indexOf("Guest") === -1 && this.state.values.maxGuests > 1 && this.state.values.partySize < this.state.values.maxGuests && this.state.values.attending === "accept")) &&
                                    <FormGroup row className={classes.formRows}>
                                        {/* <FormControlLabel labelPlacement="start" value="guestName" control={ */}
                                        <TextField id="guestName" label="Guest Name" variant="outlined" fullWidth={true} value={this.state.values.guestName} onChange={this.onChange("guestName")} className={classes.textField}/>
                                        {/* } label="Guest Name" /> */}
                                    </FormGroup>
                                }
                                {/* <Grid item xs={12} sm={8} className={classes.formRows}> */}
                                <FormGroup row className={classes.formRows}>
                                    {/* <FormControlLabel labelPlacement="start" value="message" control={ */}
                                    <TextField id="message" label="Leave a Message!" variant="outlined" rows="4" fullWidth={true} multiline={true} value={this.state.values.message} onChange={this.onChange("message")}  className={classes.textField} />
                                    {/* } label="Message" /> */}
                                </FormGroup>
                                {/* </Grid> */}

                                {/* <Grid item xs={12} sm={8} alignContent="center" justify="center" align="center" alignItems="center" > */}
                                <FormGroup row className={classes.formRows}>
                                    <Button variant="contained" type="submit" disabled={this.state.isSubmitting} >
                                        {this.state.isSubmitting ? "Submitting..." : "Submit RSVP"}
                                    </Button>
                                    <div className={`message ${this.state.isError && classes.error}`}>
                                        {this.state.message}
                                    </div>
                                </FormGroup>
                                {/* </Grid> */}
                            </form>
                        </Grid>
                        {/* </Paper> */}
                    </Grid>
                </Grid>
                {/* <Grid container spacing={2} alignContent="center" justify="center" align="center" alignItems="center">
                    <Grid item xs={12} align="center" >
                        {JSON.stringify(this.state.values)}
                    </Grid>
                </Grid> */}
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(RSVPForm);