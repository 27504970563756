import React from 'react';
import MapComponent from '../../components/map/map';
import mapConfig from './map-config.js';

const MapPage = (props) => {
    return (
    <MapComponent {...mapConfig} />
    );
  }
  
export default MapPage;