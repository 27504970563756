import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
    icon: {
      maxWidth: '50px'
    },
    toolbar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.default,
    },
    toolbarTitle: {
      flex: 1,
    },
    toolbarSecondary: {
      justifyContent: 'space-between',
      overflowX: 'auto',
      backgroundColor: theme.palette.background.default,
    },
    toolbarLink: {
      padding: theme.spacing(1),
      flexShrink: 1,
      textDecoration: 'none',
      color: theme.palette.text.primary,
      "&:hover": {
          textDecoration: 'underline'
      }
    },
    headerHero: {
      padding: "0 1em",
      margin: "2em 0"
    },
    headerHeroWeddingDate: {
      borderRight: "1px solid #000"
    },
}));

// const getNavRoutes = (page) => {
//   return page.navRoute === true;
// }


const HeroHeaderText = (props) => {
    const classes = useStyles();

    return (
      <Grid container justify={"center"}>      
      <Grid item xs={3} sm={2} align="right" className={`${classes.headerHero} ${classes.headerHeroWeddingDate}`}>
      <Typography component="h3" variant="h1" color="inherit">
        09
        <br/>
        19
        <br/>
        20
        </Typography>
      </Grid>
      <Grid item xs={2} className={classes.headerHero}>
      <Typography component="h3" variant="h1" color="inherit">
        Mill
        <br/>
        &amp;
        <br/>
        Vince
        </Typography>
      </Grid>
      <Grid item xs={7} sm={8}>

      </Grid>
      </Grid>
        )
};
HeroHeaderText.propTypes = {
};
export default HeroHeaderText;