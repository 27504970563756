import React from 'react';
import MainContent from '../../components/main-content/mainContent';
import LoginForm from '../../components/login/loginForm';
import { connect } from 'react-redux';

const mainContent = {
  title: "Login",
  content: "Please Login."
}

const Login = (props) => {
  return (
    <div>
      <MainContent title={mainContent.title} content={mainContent.content} />
      <LoginForm />
    </div>
  );
}

const mapStateToProps = state => ({
  loggedIn: state.login.loggedIn,
});
export default connect(
  mapStateToProps
)(Login)