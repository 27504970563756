import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Paper, Typography, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import turfCenter from '@turf/center';
import { featureCollection, point as turfPoint } from '@turf/helpers';
import { LngLatBounds } from 'mapbox-gl';
import React, { Component, PureComponent } from 'react';
import ReactMapGL, { FlyToInterpolator, Marker } from 'react-map-gl';
import ADDITIONAL_MARKERS from './accommodations-additional';
// import '../../components/map/map.css';
import MARKERS from './accommodations-markers';

const getCenter = () => {
  const features = MARKERS.map((marker) => turfPoint([marker.longitude, marker.latitude]));
  const center = turfCenter(featureCollection(features))
  return center;
}

let bounds = MARKERS.reduce(function(bounds, marker) {
  return bounds.extend([marker.longitude, marker.latitude]);
}, new LngLatBounds());

const styles = (theme) => ({
    root: {
      flexGrow: 1,
      // background: theme.palette.primary.main
    },
    gridContainer: {
      // height: '70vh'
    },
    gridItems: {
      height: '70vh',
      padding: '10px',
    },
    additionalOptions: {
      minHeight: '120px',
      margin: '0 10px',
      textAlign: 'center',
      paddingTop: '10px',
    },
    marker: {
      width: '25px',
      height: '25px',
      // backgroundImage: 'url(/images/double-circle.svg)',
      backgroundSize: 'contain',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      borderStyle: 'double',
      borderRadius: '25px',
    },
    importantMarker: {
      borderColor: '#02006d',
      color: '#02006d',
      borderStyle: 'solid',
    },
    markerList: {
      [theme.breakpoints.down('xs')]: {
        height: 'inherit'
      },
      overflow: 'scroll',
      padding: '0 20px',
      '&::-webkit-scrollbar': {
        '-webkit-appearance': 'none',
      },
      '&::-webkit-scrollbar:vertical': {
          width: '11px',
      },
      '&::-webkit-scrollbar:horizontal': {
          height: '11px',
      },
      '&::-webkit-scrollbar-thumb': {
          borderRadius: '8px',
          border: '2px solid white',
          backgroundColor: 'rgba(0, 0, 0, .5)',
      },
    },
    accommodationTitle: {
      textTransform: 'uppercase',
      fontWeight: 'bold'
    }
});

// const watercolorLayer = {
//   id: 'stamen-watercolor',
//   type: 'raster',
//   source: {
//     'type': 'raster',
//     'tiles': ['https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg'],
//     'tileSize': 256
//   },
//   "minzoom": 0,
//   "maxzoom": 22,
//   "paint": {
//     "raster-opacity": .8
//   },
//   beforeId: "waterway-label"
// };


// PureComponent ensures that the markers are only rerendered when data changes
class Markers extends PureComponent {
  render() {
    const { data, classes } = this.props;
    const accommodationMarkers = data.filter(marker => marker.type === "accommodation").map(
      (marker, index) => <Marker key={marker.name} longitude={marker.longitude} latitude={marker.latitude} >
        <div className={classes.marker}>{index+1}</div>
      </Marker>
    )
    const importantMarkers = data.filter(marker => marker.type === "important-marker").map(
      (marker, index) => <Marker key={marker.name} longitude={marker.longitude} latitude={marker.latitude} >
        <div className={`${classes.importantMarker} ${classes.marker}`}>{accommodationMarkers.length+index+1}</div>
      </Marker>
    )
    return [].concat(importantMarkers, accommodationMarkers);
  }
}

const StyledMarkers = withStyles(styles)(Markers);


class Map extends Component {

  state = {
    viewport: {
      latitude: getCenter().geometry.coordinates[1],
      longitude: getCenter().geometry.coordinates[0],
      zoom: 9
    }
  };

  _zoomOut = () => {
    const viewport = {
      ...this.state.viewport,
      latitude: MARKERS[0].latitude,
      longitude: MARKERS[0].longitude,
      zoom: 9,
      transitionDuration: 1000,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: (t) => {
        return t * (2 - t);
        // return Math.sin(t * Math.PI / 2);
      },
    };
    this.setState({viewport});
  }

  _flyTo = (marker) => {
    const viewport = {
      ...this.state.viewport,
      longitude: marker.longitude,
      latitude: marker.latitude,
      zoom: 14,
      transitionDuration: 2000,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: (t) => {
        return t * (2 - t);
        // return Math.sin(t * Math.PI / 2);
      },
    };
    this.setState({viewport});
  }

  constructor(props) {
    super(props);
    this.mapRef= React.createRef();
   }

  componentDidMount() {
    const map = this.mapRef;
    
    map.on('load', function () {
      // var layers = map.getStyle().layers;
      // console.log(layers);
      // map.fitBounds(bounds, {padding: 10});

      map.addLayer({
          id: 'stamen-watercolor',
          type: 'raster',
          source: {
              'type': 'raster',
              'tiles': ['https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg'],
              'tileSize': 256
          },
          "minzoom": 0,
          "maxzoom": 22,
          "paint": {
              "raster-opacity": .8
          }
        }, 'waterway-label');
      });
      // window.addEventListener('resize', map.resize);
    }



  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={12} sm={6} className={`${classes.markerList} ${classes.gridItems}`}>
            <Typography variant="h6" gutterBottom>
              Suggested Accommodations
            </Typography>
              {MARKERS.filter(marker => marker.type === "accommodation").map(
                (marker, index) => (
                  <ExpansionPanel>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className={classes.accommodationTitle}
                    >
                      {index+1}. {marker.name}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                    <Grid container spacing={0} onMouseEnter={() => this._flyTo(marker)} onMouseLeave={() => this._zoomOut()}>
                      <Grid item xs={12}>
                        {marker.rate}
                      </Grid>
                      <Grid item xs={12}>
                        {marker.details}
                      </Grid>
                      <Grid item xs={12}>
                        <a href={marker.url}>
                        Website
                        </a>
                      </Grid>
                      <Grid item xs={12}>
                        {marker.address}
                      </Grid>
                      <Grid item xs={12}>
                        {marker.phone}
                      </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                )
              )}
              <Typography variant="h6" gutterBottom>
                Important Places
              </Typography>
              {MARKERS.filter(marker => marker.type === "important-marker").map(
                (marker, index) => (
                  <ExpansionPanel>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="subtitle2" gutterBottom>
                        {MARKERS.filter(marker => marker.type === "accommodation").length + index + 1}. {marker.name}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                    <Grid container spacing={0} onMouseEnter={() => this._flyTo(marker)} onMouseLeave={() => this._zoomOut()}>
                      <Grid item xs={12}>
                        {marker.rate}
                      </Grid>
                      <Grid item xs={12}>
                        {marker.details}
                      </Grid>
                      <Grid item xs={12}>
                        <a href={marker.url}>
                        {marker.url}
                        </a>
                      </Grid>
                      <Grid item xs={12}>
                        {marker.address}
                      </Grid>
                      <Grid item xs={12}>
                        {marker.phone}
                      </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                )
              )}
          </Grid>
          <Grid item xs={12} sm={6} className={classes.gridItems}>
          <ReactMapGL
            {...this.state.viewport}
            mapboxApiAccessToken="pk.eyJ1IjoidmptMDMiLCJhIjoiY2s1bG51bXk5MHF4ajNscXlqOHgyaGNyeCJ9.cD-tzEq9-atmb7F_tIsnrA"
            onViewportChange={(viewport) => this.setState({viewport})}
            width="100%"
            height="100%"
            ref={ref => ref && (this.mapRef = ref.getMap())}
            scrollZoom={false}
            boxZoom={false}
            dragRotate={false}
            dragPan={false}
            fitBounds={bounds}
            reuseMaps={true}
            keyboard={false}
            doubleClickZoom={false}
            touchZoomRotate={false}
            touchPitch={false}
          >
          <StyledMarkers data={MARKERS} />
            {/* <Layer {...watercolorLayer} /> */}
          </ReactMapGL>
        </Grid>
        <Grid container spacing={3}>
        <Grid item xs={12} align={"center"}>
          <Typography variant="h6" gutterBottom>Additional Accommodation Options</Typography>
        </Grid>
            {ADDITIONAL_MARKERS.map(
                (marker, index) => (
                  <React.Fragment>
                    <Grid item xs={10} sm={4}>
                      <Paper elevation={4} className={classes.additionalOptions}>
                      <Typography variant="subtitle2" gutterBottom>
                        {marker.name}
                      </Typography>
                      <Grid item xs={12}>{marker.address} | {marker.phone}</Grid>
                      <Grid item xs={12}><a href={marker.url}>Website</a></Grid>
                      </Paper>
                    </Grid>
                  </React.Fragment>
                )
            )}
        </Grid>
      </Grid>
    );
  }
}

// const Map = ReactMapboxGl({
//   accessToken:
//     'pk.eyJ1IjoidmptMDMiLCJhIjoiY2s1bG51bXk5MHF4ajNscXlqOHgyaGNyeCJ9.cD-tzEq9-atmb7F_tIsnrA'
// });

// const mainContent = {
//   title: "Accommodations",
//   content: "Accommodations info goes here"
// }

const Accommodations = (props) => {

  return (
    <Map classes={props.classes} />
  );
}

export default withStyles(styles)(Accommodations);