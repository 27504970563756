const ADDITIONAL_MARKERS = [
    {
        "name": "Squam Lake Inn",
        "address": "Holderness, NH",
        "phone": "(603) 968-4417",
        "url": "https://www.squamlakeinn.com/",
    },
    {
        "name": "Econo Lodge",
        "address": "Plymouth, NH",
        "phone": "(603) 536-2330",
        "url": "https://www.choicehotels.com/new-hampshire/plymouth/econo-lodge-hotels/nh046",
    },
    {
        "name": "Airbnb",
        "address": "Recommended search: Newfound Lake, NH.",
        "phone": "Towns to consider: Hebron, Bristol, Bridgewater, Plymouth",
        "url": "https://www.airbnb.com/s/Newfound-Lake--Grafton-County--NH--United-States/homes?tab_id=all_tab&refinement_paths%5B%5D=%2Fhomes&query=Newfound%20Lake%2C%20Grafton%20County%2C%20NH&place_id=ChIJlxc8R7dQs0wRcY3n-tLuTZE&checkin=2020-09-18&checkout=2020-09-20&source=structured_search_input_header&search_type=search_query",
    }
]
export default ADDITIONAL_MARKERS;