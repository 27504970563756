import { Button, Card, CardActions, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
      cardRoot: {
        height: "100%",
        width: "100%",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        zIndex: 3,
        color: '#fff',
        padding: '30px',
        [theme.breakpoints.down('sm')]: {
          padding: '10px',
        },
      },
      cardTitle: {
        fontSize: 28,
        zIndex: 4,
        position: "relative",
        minWidth: "40px",
        maxWidth: "55%",
        fontFamily: theme.typography.fontFamilySecondary,
        fontWeight: 700,
        // textDecoration: "underline",
        "&::after": {
          content: '""',
          backgroundColor: "#fff",
          display: "block",
          height: "2px",
          margin: ".325em 0 .5em",
          width: "100%",
        },
        [theme.breakpoints.down('sm')]: {
          maxWidth: "40%",
          fontSize: 20,
        }
      },
      cardTextSecondary: {
        marginBottom: 12,
        zIndex: 5,
        position: "relative",
        textTransform: "uppercase",
        fontSize: "12px",
      },
      cardActions: {
        zIndex: 6,
        position: "relative",
      },
      icon: {
        // maxWidth: '50px'
        height: "100%",
        width: "100%",
      },
      cardLink: {
        border: "0",
        height: "100%",
        left: "0",
        position: "absolute",
        top: "0",
        width: "100%",
        zIndex: "7",
      },
      contentBlock: {
        textTransform: "inherit",
        // fontFamily: theme.typography.fontFamilySecondary,
        fontSize: 18,
        [theme.breakpoints.down('md')]: {
          fontSize: 16,
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: 14,
          fontWeight: 'bold',
        },
      }
}));

export default function HomeCard(props) {
  const classes = useStyles();
  const { title, content, content2, buttonContent, image, background, url, contentClassName } = props.config;

  return (
    <Card className={classes.cardRoot} style={{ backgroundImage: `url(${background})` }}>
            {url && 
              <Link className={classes.cardLink} to={url}></Link>
            }
            <CardContent className={classes.cardRoot}>
            {image && 
                <img src={image} alt="" className={classes.icon} />
            }
            {title &&
            <Typography variant="h5" component="h2" className={classes.cardTitle}>
                {title}
            </Typography>
            }
            <Typography variant="body1" component="p" className={`${classes.cardTextSecondary} ${classes[contentClassName]}`}>
                {content}
            </Typography>
            <Typography variant="body1" component="p" className={`${classes.cardTextSecondary} ${classes[contentClassName]}`}>
                {content2}
            </Typography>
            {buttonContent && 
                <CardActions className={classes.cardActions}>
                <Button size="small" variant="outlined">{buttonContent}</Button>
                </CardActions>
            }
            </CardContent>
        </Card>
  );
}

HomeCard.propTypes = {
};