import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Countdown from "react-countdown";
import { renderer, weddingDate } from '../../components/countdown/countdown';
import { Button } from '@material-ui/core';
import StyledPaper from '../../theme/components/paper';

const gridDemoStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      background: theme.palette.primary.main
    }
}));

const GridDemo = (props) => {
  const gridDemoClasses = gridDemoStyles();

  return (
    <div className={gridDemoClasses.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
            <Button color="primary">Primary</Button>
            <Button color="secondary">Secondary</Button>
        </Grid>
        <Grid item xs={12}>
            <StyledPaper>
            {weddingDate.toLocaleDateString("en-US")}
            </StyledPaper>
        </Grid>
        <Grid item xs={12}>
            <Countdown
            date={weddingDate}
            renderer={renderer}
            />
        </Grid>
        <Grid item xs={12}>
          <StyledPaper>xs=12</StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledPaper>xs=12 sm=6</StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledPaper>xs=12 sm=6</StyledPaper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <StyledPaper>xs=6 sm=3</StyledPaper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <StyledPaper>xs=6 sm=3</StyledPaper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <StyledPaper>xs=6 sm=3</StyledPaper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <StyledPaper>xs=6 sm=3</StyledPaper>
        </Grid>
      </Grid>
    </div>
  );
}

export default GridDemo;