import { green, grey, red } from '@material-ui/core/colors';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';


const rawTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#6c92ab',
      main: '#f3faf6',
      dark: '#0d3a50',
      contrastText: '#004500'
    },
    secondary: {
      light: '#759670',
      main: '#496845',
      dark: '#1f3d1d',
      contrastText: '#ffffff'
    },
    warning: {
      main: '#ffc071',
      dark: '#ffb25e',
    },
    error: {
      xLight: red[50],
      main: red[500],
      dark: red[700],
    },
    success: {
      xLight: green[50],
      main: green[500],
      dark: green[700],
    },
    text: {
      // primary: '#f1eeee',
      primary: '#004500',
      secondary: '#004500',
      pantoneEmerald: '#009874',
      emerald: '#02582f',
      sage:  '#798b7f',
      summerGreen: '#9ab4a9',
      cornflowerBlue: '#8db1c7',
      slateBlue: '#a1b1b8',
    }
  },
  typography: {
    fontFamily: "'Lato', sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 900,
    fontFamilySecondary: "'Antic Didone', serif",
  },
});

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: rawTheme.typography.fontWeightMedium,
  fontFamily: rawTheme.typography.fontFamilySecondary,
  textTransform: 'uppercase',
  letterSpacing: "5px",
};

const fullTheme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      // default: rawTheme.palette.common.white,
      // default: '#aac4b9',
      // default: '#9ab4a9',
      default: '#fffff9',
      placeholder: grey[200],
    },
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      // letterSpacing: 0,
      // fontSize: 60,
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      // fontSize: 48,
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      // fontSize: 42,
    },
    h4: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      fontSize: "2.5rem",
      letterSpacing: "10px",
    },
    h5: {
      ...rawTheme.typography.h5,
      // fontSize: 20,
      fontWeight: rawTheme.typography.fontWeightRegular,
    },
    h6: {
      ...rawTheme.typography.h6,
      ...fontHeader,
      // fontSize: 18,
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      // fontSize: 18,
    },
    subtitle2: {
      ...rawTheme.typography.subtitle2,
      color: rawTheme.palette.text.primary,
      textTransform: 'uppercase',
      fontWeight: 'bold',
      // fontSize: 18,
    },
    body1: {
      ...rawTheme.typography.body2,
      fontWeight: rawTheme.typography.fontWeightRegular,
      // fontSize: 16,
    },
    body2: {
      ...rawTheme.typography.body1,
      // fontSize: 14,
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': "'Lato', sans-serif",
      },
    },
  },
};

export const theme = responsiveFontSizes(fullTheme);

export default theme;
