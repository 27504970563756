import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import HomeGridPanel from '../../components/home/home-grid-panel';
import HomeHeroImage from '../../components/home/home-hero-image';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      marginTop: "35px",
      // background: theme.palette.primary.main
    },
}));

// const heroImage = {
//   title: weddingDate.toLocaleDateString("en-US"),
//   description: "",
//   image: '/images/mill-vince-small.jpg',
//   imgText: 'main image description',
// };

const Home = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <HomeHeroImage />
      <HomeGridPanel />
    </div>
  );
}

export default Home;