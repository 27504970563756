import { Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from "react-router-dom";
import NavDrawer from '../nav/drawer';

const getNavRoutes = (page) => {
  return page.navRoute === true;
}

// TODO: delete this page? i dont think we are using this

const useStyles = makeStyles(theme => ({
    icon: {
      maxWidth: '50px'
    },
    toolbar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.default,
    },
    toolbarTitle: {
      flex: 1,
    },
    toolbarSecondary: {
      justifyContent: 'space-between',
      overflowX: 'auto',
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    toolbarLink: {
      padding: theme.spacing(1),
      flexShrink: 1,
      textDecoration: 'none',
      color: theme.palette.text.sage,
      "&:hover": {
          textDecoration: 'underline'
      },
      textTransform: 'uppercase'
    },
}));


const Nav = (props) => {
    const classes = useStyles();
    const { pageRoutes } = props;
    const navRoutes = pageRoutes.filter(getNavRoutes);

    return (
    <React.Fragment>
      <NavDrawer navRoutes={navRoutes} />
      <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
        {navRoutes.map(page => (
          <Link
            // color="inherit"
            // noWrap
            key={page.title}
            variant="body2"
            // href={page.url}
            to={page.url}
            className={classes.toolbarLink}
          >
            {page.title}
          </Link>
        ))}
      </Toolbar>
    </React.Fragment>
        )
};
Nav.propTypes = {
    pageRoutes: PropTypes.array,
    title: PropTypes.string,
};
export default Nav;