import { Typography, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';


const styles = (theme) => ({
    pageHeaderContainer: {
        margin: "35px 0",
        color: theme.palette.text.sage,
        fontWeight: 400,
    }
});

class PageSubheader extends Component {
    render() {
        const { classes, children } = this.props;
        return (
            <Grid container spacing={2} justify="center" alignItems="center" className={classes.pageHeaderContainer}>
                <Grid item xs={8}>
                    <Typography align="center" variant="h5" gutterBottom>
                        {children}
                    </Typography>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(PageSubheader);