import React from 'react'
import { connect } from 'react-redux'
import { submitLoginForm } from '../../store/actions'
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
const LoginForm = ({ dispatch }) => {
  let input
  return (
    <Grid container spacing={2} alignContent="center" justify="center" align="center" alignItems="center">
    <Grid item xs={12} align="center" >
      <form
        onSubmit={e => {
          e.preventDefault()
          if (!input.value.trim()) {
            return
          }
          dispatch(submitLoginForm(input.value))
          input.value = ''
        }}
      >
        <input type="password" ref={node => (input = node)} />
        <Button variant="outlined" size="small" type="submit">Login</Button>
      </form>
      </Grid>
      </Grid>
  )
}
export default connect()(LoginForm)