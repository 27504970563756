import React from 'react';
import { Grid, Paper, makeStyles, Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
  paper: {
    // padding: theme.spacing(2),
    // margin: 'auto',
    // maxWidth: 500,
    // backgroundColor: '#004500',
    // color: '#f1eeee',
  },
  root: {
    backgroundColor: '#fff',
    minHeight: '100vh'
  }
}));

const defaultProps = {
  margin: '10px',
  // bgcolor: '#000',
  // borderColor: 'text.primary',
  m: 1,
  border: 0,
  style: { width: '5rem', height: '5rem' },
  // backgroundColor: '#000'
};

const weddingColors = [
  {
    hex:'#009874',
    colorName:'Pantone Emerald',
  },
  {
    hex:'#02582f',
    colorName:'Emerald',
  },
  {
    hex:'#798b7f',
    colorName:'Sage',
  },
  {
    hex:'#9ab4a9',
    colorName:'Summer Green',
  },
  {
    hex:'#8db1c7',
    colorName:'Cornflower Blue',
  },
  {
    hex:'#a1b1b8',
    colorName:'Slate Blue',
  },
]

const ColorPalette = (props) => {
  const classes = useStyles();
  return (
  
  <Grid className={classes.root} container spacing={2} alignContent="center" justify="center" align="center" alignItems="center">
    {weddingColors.map(weddingColor => (
      <Grid item xs={12} sm={4} md={2} >
      <Box bgcolor={weddingColor.hex} borderRadius="50%" {...defaultProps} />
      <Paper>{weddingColor.colorName}</Paper>
    </Grid>
    ))}
  </Grid>
  );
}

export default ColorPalette;