import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import PageSubheader from '../../components/header/page-subheader';

const useStyles = makeStyles(theme => ({
    pageHeaderGrid: {
        marginTop: "35px",
        marginBottom: "35px",
        backgroundColor: theme.palette.background.default,
    },
    inkBranch: {
        maxWidth: "85px",
        marginTop: "5px",
    },
    subheaderSection: {
        marginBottom: "5px",
        fontSize: "16px",
    },
    subheaderDivider: {
        maxWidth: "35px",
        margin: "0",
    },
}));


const PageHeader = (props) => {
    const classes = useStyles();

    return (
        <Grid container spacing={0} className={classes.pageHeaderGrid}>
            <Grid item xs={12} align="center" >
                <Typography variant="h4">
                    {props.title}
                </Typography>
            </Grid>
            <Grid item xs={12} align="center" >
                <img src='/images/divider.svg' alt={"M & V"} className={classes.inkBranch} />
            </Grid>
            <PageSubheader>{props.subheader && props.subheader.map((subheaderSection, index) => (
                <React.Fragment>
                    <Grid item xs={12} align="center" className={classes.subheaderSection}>
                        {subheaderSection}
                    </Grid>
                    <Grid item xs={12} align="center" className={classes.subheaderSection}>
                        { props.subheader.length !== (index + 1) &&
                            <img src='/images/divider-2.svg' alt={"M & V"} className={classes.subheaderDivider} />
                        }
                    </Grid>
                </React.Fragment>
            ))}</PageSubheader>
        </Grid>
    )
};
PageHeader.propTypes = {
    title: PropTypes.string,
};
export default PageHeader;