import { Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Nav from '../nav/nav';
import HeroHeaderText from './hero-header-text';
import SmallHeaderText from './small-header-text';

const useStyles = makeStyles(theme => ({
    icon: {
      maxWidth: '50px'
    },
    toolbar: {
      // borderBottom: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.default,
    },
    toolbarTitle: {
      flex: 1,
    },
    toolbarSecondary: {
      justifyContent: 'space-between',
      overflowX: 'auto',
      backgroundColor: theme.palette.background.default,
      marginBottom: "18px"
    },
    toolbarLink: {
      padding: theme.spacing(1),
      flexShrink: 1,
      textDecoration: 'none',
      color: theme.palette.text.primary,
      "&:hover": {
          textDecoration: 'underline'
      },
      textTransform: 'uppercase',
    },
    headerHero: {
      padding: "0 1em",
      margin: "2em 0"
    },
    headerHeroWeddingDate: {
      borderRight: "1px solid #000"
    },
    dividerStyle: {
      // borderStyle: "none none double none",
      // background: "none",
      marginTop: "2px",
      height: "2px",
      background: "#02582f",
    },
    logoLineWeddingDate: {
      verticalAlign: "middle"
    },
    weddingDateAndPlace: {
      textAlign: "right",
      paddingRight: "5px",
      textTransform: "uppercase",
      marginBottom: "20px",
    },
    gridContainer: {
      backgroundColor: theme.palette.background.default,
    },
}));

const headerText = (heroText) => {
  if (heroText === true) {
    return <HeroHeaderText/>;
  } else {
    return <SmallHeaderText/>
  }
}




const Header = (props) => {
    const classes = useStyles();
    const { pageRoutes } = props;

    return (
    <React.Fragment>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} className={classes.weddingDateAndPlace}>
          September 19, 2020 | Bristol, NH
        </Grid>
      </Grid>
      {headerText(props.heroText)}
      <Nav pageRoutes={pageRoutes} />
      <Divider className={classes.dividerStyle} />
      <Divider className={classes.dividerStyle} />
    </React.Fragment>
        )
};
Header.propTypes = {
    pageRoutes: PropTypes.array,
    title: PropTypes.string,
};
export default Header;