import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import React from 'react';

const styles = (theme) => ({
    root: {
      textAlign: 'center',
    },
    subheader: {
        textTransform: 'uppercase',
        marginBottom: '10px',
      },
      partystillon: {
        marginTop: '30px',
      },
      divider: {
      width: '35px',
      margin: '10px 0',
    },
    paragraph: {
      marginBottom: '10px',
    },
});

class ThingsToDo extends React.Component {
  render() {
    const { classes } = this.props;
    return (
        <React.Fragment>
            <Grid container spacing={3} alignItems="center" justify="center" className={classes.root}>
            <Grid item xs={12} sm={7}>
            <Typography variant="h6" component="h4" className={`${classes.subheader} ${classes.partystillon}`}>
            The Party's Still On!
            </Typography>
            <Typography variant="body2" className={classes.paragraph}>
              Hi Family and Friends,
            </Typography>
            <Typography variant="body2" className={classes.paragraph}>
            We are so excited to celebrate with you in the coming weeks! Thank you for making the exceptional effort to travel and witness our matrimony during this time - we are truly honored by your presence.
            </Typography>
            <Typography variant="body2" className={classes.paragraph}>
            We understand that gatherings such as ours inherently introduce more risk than your day-to-day routine, and we are adhering to state and CDC guidelines to reduce covid-19 related risk. We are confident that the measures we have taken, coupled with the majority of the day being held outdoors, socially distanced and wearing masks, will create the safest and most comfortable occasion for our guests. For your reference, our current guest count is at 94, with 4-15 event staff throughout the evening. NH has introduced a new mask ordinance for events over 100, so please make sure to read the info packet below, as this applies to us.
            </Typography>
            <Typography variant="body2" className={classes.paragraph}>
            We also ask that as guests, you follow the latest guidance from the state of NH and CDC regarding any illness or recent exposure to covid-19, including consulting health professionals or getting tested when applicable. We trust that each of you is making the best decision for you and your families as well as other guests leading up to and during the event. 
            </Typography>
            <Typography variant="body2" className={classes.paragraph}>
            If you have any questions or concerns on what to expect or you can no longer make the event, we ask that you reach out to Mill or Vince directly via mobile or email.
            </Typography>
            <Typography variant="body2" className={classes.paragraph}>
            In the PDF in the information below, we want to share specific covid-19 related policies for travel and events in New Hampshire and surrounding New England, as well as the safety accommodations we’ve introduced for our wedding weekend to provide the details required for your planning.
            </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>With Love, Mill and Vince</Grid>
            <Grid item xs={12} sm={7}><img src={'/images/divider-2.svg'} alt='' className={classes.divider}></img></Grid>
            <Grid item xs={12} sm={7}>
                Last updated: September 8, 2020
            </Grid>
            <Grid item xs={12} sm={7}>
            <Paper elevation={4} className={classes.card}>
            <Typography align="center" variant="h6" className={classes.categoryTitle} gutterBottom>Wedding Weekend Packet</Typography>
            <Grid item xs={12}><a href="/pdf/wedding-weekend-details.pdf">Wedding Weekend Details PDF</a></Grid>
            </Paper>
            </Grid>
            <Grid item xs={12} sm={7}><img src={'/images/divider-2.svg'} alt='' className={classes.divider}></img></Grid>
            <Grid item xs={12} sm={7}>
            <Typography variant="h6" component="h4" className={classes.subheader}>
            Information
            </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
            <Typography variant="body2" className={classes.paragraph}>
            We've posted some helpful links from the state of New Hampshire below. We will keep this space up to date as changes get implemented, but will reach out to you directly if anything major changes!
            </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
            <Paper elevation={4} className={classes.card}>
            <Typography align="center" variant="h6" className={classes.categoryTitle} gutterBottom>State of NH</Typography>
            <Grid item xs={12}><a href="https://www.covidguidance.nh.gov/sites/g/files/ehbemt381/files/inline-documents/2020-06/guidance-weddings.pdf">New Hampshire Stay at Home 2.0 Official Wedding Guidance</a></Grid>
            <Grid item xs={12}><a href="https://www.covidguidance.nh.gov/">NH Coronavirus Guidance - Stay at Home 2.0</a></Grid>
            <Grid item xs={12}><a href="https://www.governor.nh.gov/sites/g/files/ehbemt336/files/documents/emergency-order-63.pdf">Emergency Order 63 (NH Large Gathering Mask Ordinance)</a></Grid>
            </Paper>
            </Grid>
            <Grid item xs={12} sm={7}>
            <Paper elevation={4} className={classes.card}>
            <Typography align="center" variant="h6" className={classes.categoryTitle} gutterBottom>CDC Guidelines</Typography>
            <Grid item xs={12}><a href="https://www.cdc.gov/coronavirus/2019-ncov/community/large-events/considerations-for-events-gatherings.html">Considerations for Events and Gatherings</a></Grid>
            <Grid item xs={12}><a href="https://www.cdc.gov/coronavirus/2019-ncov/daily-life-coping/food-and-COVID-19.html">Coronavirus and Food – Information</a></Grid>
            </Paper>
            </Grid>
            <Grid item xs={12} sm={7}><img src={'/images/divider-2.svg'} alt='' className={classes.divider}></img></Grid>
            </Grid>
        </React.Fragment>
    );
  }
}

export default withStyles(styles)(ThingsToDo);