import { CssBaseline, ThemeProvider } from '@material-ui/core';
import React from 'react';
import GoogleFontLoader from 'react-google-font-loader';
import './App.css';
import { pageRoutes } from './routes/routes';
import RouteSwitch from './routes/routeSwitch';
import { theme } from './theme/theme';


function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GoogleFontLoader
      fonts={[
        {
          font: 'Lato',
          weights: [300, 400, 700, 900],
        },
        {
          font: 'Antic Didone',
          weights: [400, 700],
        }
      ]}
      // subsets={['cyrillic-ext', 'greek']}
    />
      <RouteSwitch pageRoutes={pageRoutes} />
    </ThemeProvider>
  );
}

export default App;
