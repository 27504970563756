import { Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const StyledPaper = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        textAlign: 'center',
        background: theme.palette.secondary.light,
        color: theme.palette.text.secondary,
    },
  }))(Paper);

export default StyledPaper;