import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import THINGS_TO_DO from './things-to-do-details.js';


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',
  },
  card: {
    minHeight: '230px',
    margin: '20px 10px',
    textAlign: 'center',
    padding: '10px',
    fontWeight: 300,
    // fontSize: '13px',
  },
  cardTitle: {
    textTransform: 'uppercase',
    fontFamily: 'Antic Didone',
    '& a': {
      color: theme.palette.primary.contrastText
    }
  },
  categoryTitle: {
    paddingLeft: '10px',
  },
  suggestionName: {
    fontWeight: 'bold'
  },
  divider: {
    width: '35px',
    margin: '10px 0',
  },
});

function httpHtml(content) {
  const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
  return content.replace(reg, "<a href='$1$2'>$1$2</a>");
}

class ThingsToDo extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
      <Grid container spacing={3} alignItems="center" justify="center">
        {THINGS_TO_DO.map((category) => (
          <React.Fragment>
            <Grid item xs={12}>
            <Typography align="center" variant="h6" className={classes.categoryTitle} gutterBottom>{category.categoryName}</Typography>
            <div dangerouslySetInnerHTML={{ __html: httpHtml(category.description) }}></div>
            </Grid>
            {category.suggestions.map((suggestion) => (
              <React.Fragment>
                <Grid item xs={12} sm={4} alignItems="center" justify="center">
                <Paper elevation={4} className={classes.card}>
                  <div textAlign="center" className={`${classes.suggestionName} ${classes.cardTitle}`}><a href={suggestion.url}>{suggestion.name}</a></div>
                  <div>{suggestion.address}</div>
                  <img src={'/images/divider-2.svg'} alt='' className={classes.divider}></img>
                  <div>{suggestion.description}</div>
                </Paper>
                </Grid>
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ThingsToDo);