const chapters = [
    {
        id: 'Intro',
        cardType: 'intro',
        title: 'Events',
        image: '/images/divider.svg',
        description: 'We look forward to a joyful celebration with you! Scroll down for wedding weekend details.',
        // date: 'Friday, September 18th, 2020',
        // time: '8PM to 10PM',
        // locationName: "The Grotto",
        // address: ['5 Railroad Square', 'Plymouth, NH 03264'],
        // url: 'https://grottoplymouth.com/',
        // urlLabel: 'The Grotto Website',
        location: {
            center: [-71.6868859, 43.7555755],
            zoom: 13,
            pitch: 0,
            bearing: 0
        },
        onChapterEnter: [
            // {
            //     layer: 'layer-name',
            //     opacity: 1
            // }
        ],
        onChapterExit: [
            // {
            //     layer: 'layer-name',
            //     opacity: 0
            // }
        ]
    },
    // {
    //     id: 'welcome-drinks',
    //     title: 'Welcome Drinks',
    //     image: '/images/the-grotto.svg',
    //     description: 'Thanks for being here! Join us for welcome drinks to kick off the weekend with casual drinks. Casual Attire.',
    //     // description: 'All are welcome! Details coming soon.',
    //     date: 'Friday, September 18th, 2020',
    //     time: '8PM to 10PM',
    //     locationName: "The Grotto",
    //     address: ['5 Railroad Square', 'Plymouth, NH 03264'],
    //     url: 'https://grottoplymouth.com/',
    //     urlLabel: 'The Grotto Website',
    //     location: {
    //         center: [-71.6868859, 43.7555755],
    //         zoom: 13,
    //         pitch: 0,
    //         bearing: 0
    //     },
    //     onChapterEnter: [
    //         // {
    //         //     layer: 'layer-name',
    //         //     opacity: 1
    //         // }
    //     ],
    //     onChapterExit: [
    //         // {
    //         //     layer: 'layer-name',
    //         //     opacity: 0
    //         // }
    //     ]
    // },
    {
        id: 'wedding',
        title: 'Wedding',
        image: '/images/ourladyofgracechurch.svg',
        description: 'Join us for "I Do" - Cocktail Attire',
        date: 'Saturday, September 19th, 2020',
        time: '2PM',
        locationName: 'Our Lady of Grace Chapel',
        address: ['17 West Shore Road', 'Bristol, NH 03222'],
        url: 'https://www.holytrinityparishnh.org/mass-times-and-directions.html',
        urlLabel: 'Church Details',
        location: {
            center: [-71.737520, 43.616830],
            zoom: 13,
            pitch: 0,
            bearing: 0
        },
        onChapterEnter: [],
        onChapterExit: []
    },
    {
        id: 'cocktail-hour',
        title: 'Cocktail Hour',
        image: '/images/meadow-wind.svg',
        description: 'Cocktails and Mountain Views - Cocktail Attire, Adult Only',
        date: 'Saturday, September 19th, 2020',
        time: '3:30PM',
        locationName: 'Meadow Wind',
        address: ['41 North Shore Road', 'Hebron, NH, 03241'],
        url: 'https://www.meadowwind.com/',
        urlLabel: 'Meadow Wind Website',
        location: {
            center: [-71.804140, 43.693750],
            zoom: 13,
            pitch: 0,
            bearing: 0
        },
        onChapterEnter: [],
        onChapterExit: []
    },
    {
        id: 'reception',
        title: 'Reception',
        image: '/images/meadow-wind.svg',
        description: 'Dinner and Dancing - Cocktail Attire, Adult Only',
        date: 'Saturday, September 19th, 2020',
        time: '5PM - 10PM',
        locationName: 'Meadow Wind',
        address: ['41 North Shore Road', 'Hebron, NH, 03241'],
        url: 'https://www.meadowwind.com/',
        urlLabel: 'Meadow Wind Website',
        location: {
            center: [-71.804140, 43.693750],
            zoom: 13,
            pitch: 0,
            bearing: 0
        },
        onChapterEnter: [],
        onChapterExit: []
    },
    {
        id: 'after-party',
        title: 'After Party',
        image: '/images/cocktail-hour.svg',
        description: 'After Party at The Bridgewater Tavern',
        time: '10pm - ?',
        address: ['367 Mayhew Turnpike', 'Bristol, NH 03222'],
        url: 'http://www.bridgewater-inn.com/index.html',
        location: {
            center: [-71.736070, 43.643820],
            zoom: 13,
            pitch: 0,
            bearing: 0
        },
        onChapterEnter: [],
        onChapterExit: []
    },
    {
        id: 'farewell-brunch',
        title: 'Farewell Brunch',
        image: '/images/mountain.svg',
        description: 'Grab a bite before heading home - Casual Attire',
        date: 'Sunday, September 20th, 2020',
        time: '11AM - 1PM',
        locationName: 'Scott Family Airbnb',
        // address: ['94 Mandi Lane', 'Bristol, NH 03222'],
        // url: '#',
        // urlLabel: 'Airbnb',
        location: {
            center: [-71.718120, 43.631920],
            zoom: 13,
            pitch: 0,
            bearing: 0
        },
        onChapterEnter: [],
        onChapterExit: []
    },
]

export const config = {
    style: 'mapbox://styles/mapbox/light-v10',
    // style: 'mapbox://styles/mapbox/streets-v11',
    // style: {
    //     'version': 8,
    //     'sources': {
    //         'raster-tiles': {
    //             'type': 'raster',
    //             'tiles': [
    //                 'https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg'
    //             ],
    //             'tileSize': 256,
    //             'attribution':
    //                 'Map tiles by <a target="_top" rel="noopener" href="http://stamen.com">Stamen Design</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a target="_top" rel="noopener" href="http://openstreetmap.org">OpenStreetMap</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by-sa/3.0">CC BY SA</a>'
    //         }
    //     },
    //     'layers': [
    //         {
    //             'id': 'simple-tiles',
    //             'type': 'raster',
    //             'source': 'raster-tiles',
    //             'minzoom': 0,
    //             'maxzoom': 22
    //         }
    //     ]
    // },
    accessToken: 'pk.eyJ1IjoidmptMDMiLCJhIjoiY2s1bG51bXk5MHF4ajNscXlqOHgyaGNyeCJ9.cD-tzEq9-atmb7F_tIsnrA',
    showMarkers: true,
    theme: 'light',
    alignment: 'left',
    // title: 'Wedding Events',
    // subtitle: 'More details coming soon!',
    // byline: 'intended for author byline, might delete this line',
    // footer: 'intended as a footer, might delete this line',
    chapters: chapters
};

export default config;