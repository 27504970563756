const cardConfig = [
    {
        title: '',
        image: '/images/m-and-v-green.svg',
    },
    {
        title: 'Thank You & Updates',
        content: "We are thrilled to have you join us in celebrating our marriage and are so thankful for your love and support!",
        content2: "We are monitoring the Coronavirus situation closely, and still hope you can make it! We'll be reaching out and posting any coronavirus related updates or restrictions on our site over the next couple of months.",
        background: '/images/trail-signs.jpg',
        contentClassName: "contentBlock",
        buttonContent: 'Latest Updates',
        url: '/covid-19',
    },
    {
        title: 'RSVP',
        content: 'Click here to RSVP online',
        buttonContent: 'RSVP Now',
        background: '/images/newfound-lake-vista.jpg',
        url: '/rsvp',
    },
    {
        title: 'Events',
        content: 'Schedule of Events for the wedding weekend',
        buttonContent: 'Event Details',
        background: '/images/newfound-lake-shoreline.jpg',
        url: '/events',
    },
    {
        title: 'Travel',
        content: 'Getting to The Granite State',
        buttonContent: 'Travel Details',
        background: '/images/newfound-lake-dam.jpg',
        url: '/travel',
    },
    {
        title: 'Accommodations',
        content: 'Where to Stay in the Lakes Region',
        buttonContent: 'Reservation Info',
        background: '/images/audubon-society-sign.jpg',
        url: '/accommodations',
    },
    {
        title: 'Things To Do',
        content: 'Quintessential New Hampshire experiences',
        buttonContent: 'Discover',
        background: '/images/meadow-wind-overview.jpg',
        url: '/things-to-do',
    },
    {
        title: 'Registry',
        content: 'Coming Soon!',
        buttonContent: 'Details',
        background: '/images/meadow-wind-barn-entrance.jpg',
        url: '/registry',
    },
];
export default cardConfig;