import { combineReducers } from 'redux'
import todos from './todos'
import login from './login'
import visibilityFilter from './visibilityFilter'
import { reducer as formReducer } from 'redux-form'
export default combineReducers({
  todos,
  login,
  visibilityFilter,
  form: formReducer
})