const TRAVEL_DETAILS = {
    "fly": [{
        "name": "Manchester-Boston Airport",
        "code": "MHT",
        "address": "Manchester, NH",
        "url": "https://www.flymanchester.com/",
        "travelTime": "1 hour 15 minute drive to Lakes Region Area",
    },
    {
        "name": "Boston Logan International Airport",
        "code": "BOS",
        "address": "Boston, MA",
        "url": "https://www.massport.com/logan-airport",
        "travelTime": "2 hour drive to Lakes Region Area",
    }],
    "drive": [{
        "name": "Directions from Boston",
        "directionSteps": [
            "Take I-93 North to New Hampshire. Depending on your lodging, take the following Exits:",
            "Exit 23: Newfound Lake Area - Bridgewater, Bristol & Hebron",
            "Take Exit 23 towards Meredith and New Hampton. Turn left at ramp bottom onto Route 104 West (Ragged Mountain Highway). Follow Route 104 to Bristol and at traffic light, continue straight onto Route 3A North. Follow directions to appropriate address around the lake.",
            "Exit 24: Ashland",
            "Exit 25 & 26: Plymouth",
            "Exit 27: Campton",
        ]
    },
    {
        "name": "Directions from NY / CT",
        "directionSteps": [
            "Take I-95 East to New Haven, connecting to I-91 North.",
            "Follow I-91 to I-89 South (Vermont Exit 10S, marked New Hampshire and Airport).",
            "Follow I-89 South into New Hampshire and on to Exit 17 towards Route 4A/Enfield/Canaan.",
            "Turn right at ramp bottom onto Route 4 East (Dartmouth College Highway).",
            "Take Route 4 to Canaan, and in downtown Canaan, bear left on Route 118 toward Dorchester and Rumney.",
            "Follow Route 118 for 13 miles to N. Groton Road on right.",
            "Take N. Groton Road through Groton and into Hebron."
        ]
    }]
};

export default TRAVEL_DETAILS;