const THINGS_TO_DO = [
    {
        "categoryName": "Dining - Cafes & Casual",
        "description": "",
        "icon": "",
        "suggestions": [{
            "name": "BLENDED",
            "address": "Plymouth",
            "description": "Grab fresh juices, smoothies or acai bowls to kick start your day!",
            "url": "https://www.blendednh.com/",
        },
        {
            "name": "Cafe Monte Alto and Deli",
            "address": "Plymouth",
            "description": "Local coffee spot downtown and with little nooks to sit and enjoy your start to the day... make sure to hit the deli for their and serious breakfast and lunch sandwiches like their famous balboa.",
            "url": "https://www.montealto.com/",
        },
        {
            "name": "Main Street Station",
            "address": "Plymouth",
            "description": "Delicious breakfast and diner food in an old trolley car.",
            "url": "https://www.themainstreetstationnh.com/",
        },
        {
            "name": "The Last Chair Brewery",
            "address": "Plymouth",
            "description": "A fun new addition to the local scene with bar food and some great local beer.",
            "url": "https://www.thelastchairnh.com/",
        },
        {
            "name": "The Grotto",
            "address": "Plymouth",
            "description": "Legitimate pizza in a renovated warehouse with a huge wrap around bar.",
            "url": "https://grottoplymouth.com/",
        },
        {
            "name": "The Big Catch",
            "address": "Bristol",
            "description": "Casual seafood spot on a corner of Newfound Lake.",
            "url": "https://thebigcatch.business.site/",
        },
        {
            "name": "The Common Man Locations",
            "address": "Lakes Region Area",
            "description": "(Italian Farmhouse, Lago, Camp, Town Docks and more) - From Plymouth to Meredith, any restaurant in The Common Man family is a reliable and fun spot with country or quirky themes with twists on American or Italian cuisine.",
            "url": "https://www.thecman.com/restaurants-and-menus/",
        }]
    },
    {
        "categoryName": "Dining - More Upscale",
        "description": "",
        "icon": "",
        "suggestions": [{
            "name": "Six Burner Bistro",
            "address": "Plymouth",
            "description": "Delicious New American food and cocktails in an old farmhouse down the street from the Scott residence!",
            "url": "https://www.sixburnerbistro.com/",
        },
        {
            "name": "Walter’s Basin",
            "address": "Holderness",
            "description": "Enjoy delicious and beautifully plated seafood overlooking Squam Lake.",
            "url": "https://www.waltersbasin.com/",
        },
        {
            "name": "Squam Inn Kitchen + Bar",
            "address": "Holderness",
            "description": "New American cuisine in a posh inn overlooking Squam Lake.",
            "url": "https://www.squamlakeinn.com/inn-kitchen-bar/",
        },
        {
            "name": "Covered Bridge Farmtable",
            "address": "Campton",
            "description": "Farm to table menu in renovated digs and patio seating next to the Blair Covered Bridge.",
            "url": "https://www.farmtablenh.com/",
        },
        {
            "name": "The Homestead Restaurant & Tavern",
            "address": "Bristol",
            "description": "Popular, family run American Tavern with delicious New England comfort food.",
            "url": "https://homesteadnh.com/",
        }]
    },
    {"categoryName": "Parks & Hiking",
    "description": "For a full list and further details, check out https://www.visitnh.gov/ and https://www.nhstateparks.org and https://www.lakesregion.org/",
    "icon": "",
    "suggestions": [{
        "name": "Wellington State Park and Beach",
        "address": "Bristol",
        "description": "Hiking trails, kayaking and beach access on Newfound lake. Kayak rentals available.",
        "url": "https://www.nhstateparks.org/visit/state-parks/wellington-state-park",
    },
    {
        "name": "Cardigan Mountain State Park",
        "address": "Orange",
        "description": "Close by Mountain to Newfound lake with intermediate to hard trails.",
        "url": "https://www.nhstateparks.org/visit/state-parks/cardigan-mountain-state-park",
    },
    {
        "name": "Newfound Lake",
        "address": "Bridgewater, Bristol, Hebron",
        "description": "One of the largest clean lakes in the world!",
        "url": "https://www.lakesregion.org/newfound-lake/",
    },
    {
        "name": "Squam Lake",
        "address": "Holderness, Ashland",
        "description": "Little and Big Squam are where the film “On Golden Pond” was filmed.",
        "url": "http://www.visitnh.gov/information/about-the-regions/lakes-region.aspx",
    },
    {
        "name": "Lake Winnipesaukee",
        "address": "Laconia, Meredith",
        "description": "The largest lake in the state and where the Montalbano family spent summers on Cow Island for many years.",
        "url": "https://www.winnipesaukee.com/",
    },
    {
        "name": "West Rattlesnake Mountain",
        "address": "Holderness",
        "description": "A popular and family friendly hike to a beautiful view overlooking Squam Lake.",
        "url": "https://www.hikenewengland.com/RattlesnakeWestGen1.php",
    },
    {
        "name": "Welch and Dickey Loop Trail",
        "address": "Thornton",
        "description": "Popular, intermediate hike.",
        "url": "https://www.hikenewengland.com/WelchDickey030719.php",
    },
    {
        "name": "Sculptured Rocks",
        "address": "Groton",
        "description": "Lively water and rocks formation along the Cockermouth River that feeds into Newfound Lake.",
        "url": "https://www.nhstateparks.org/visit/state-parks/sculptured-rocks-natural-area.aspx",
    },]},
    {"categoryName": "Attractions",
    "description": "",
    "icon": "",
    "suggestions": [{
            "name": "The Flying Monkey Movie House and Performance Center",
            "address": "Plymouth",
            "description": "Retro theater with live music and shows.",
            "url": "https://www.flyingmonkeynh.com/",
        },
        {
            "name": "Polar Caves",
            "address": "Rumney",
            "description": "A childhood favorite of the Scotts! Family friendly natural caves to explore.",
            "url": "http://polarcaves.com/",
        },
        {
            "name": "Squam Lakes Natural Science Center",
            "address": "Holderness",
            "description": "Family friendly space to learn about nature.",
            "url": "http://www.nhnature.org/",
        },{
            "name": "The Museum of the White Mountains",
            "address": "Plymouth",
            "description": "Learn more about NH history and the legacy of literary greats who were inspired by the White Mountains, like Robert Frost.",
            "url": "http://www.plymouth.edu/museum-of-the-white-mountains/",
        },
        {
            "name": "Smith Covered Bridge",
            "address": "Plymouth",
            "description": "A local covered bridge and memorial site.",
            "url": "https://www.nh.gov/nhdhr/bridges/p83.html",
        },
        {
            "name": "Blair Covered Bridge",
            "address": "Campton",
            "description": "Another favorite covered bridge, and next to the Covered Bridge Farmtable restaurant.",
            "url": "https://www.nh.gov/nhdhr/bridges/p77.html",
        },
        {
            "name": "Lake Winnipesaukee Boat Tour",
            "address": "Meredith/Laconia",
            "description": "Experience Lake Winnipesaukee aboard the historical M/S Mount Washington.",
            "url": "https://cruisenh.com/",
        },
        {
            "name": "The Common Man General Store",
            "address": "Ashland",
            "description": "Fun knick knacks for the home, local gifts and fudge in a nod to general stores of the past.",
            "url": "https://www.thecman.com/restaurants-and-menus/common-man-restaurants/common-man-ashland.aspx",
        },
        {
            "name": "NH Scot Highland Games",
            "address": "Franconia",
            "description": "This annual festival draws in a crowd ready to celebrate and throw some logs and dance!",
            "url": "https://nhscot.org/",
        },
        {
            "name": "Main Street",
            "address": "Plymouth",
            "description": "Smalltown mainstreet around the corner from where Mill grew up with local boutiques and art shops. Favorites include Dressers Unlimited, Simply Sunflowers and Samahas Five and Dime.",
            "url": "https://www.dressersunlimited.com/",
        }]
    },
    {
        "categoryName": "If you make it to White Mountains!",
        "description": "",
        "icon": "",
        "suggestions": [{
            "name": "The Cannon Mountain Aerial Tramway",
            "address": "Franconia",
            "description": "Take in the views of Franconia Notch at Cannon Mountain and aboard The Cannon Mountain Aerial Tramway. A great place for a picnic and to view the Old Man on the Mountain Memorial.",
            "url": "https://www.cannonmt.com/things-to-do/attractions/tram",
        },
        {
            "name": "Mount Washington Cog Railway",
            "address": "Bretton Woods",
            "description": "Experience Mount Washington via the Mount Washington Cog Railway, a three-hour round trip on the world’s first mountain-limbing cog railway, and pop into the Mount Washington Observatory at the top.",
            "url": "http://thecog.com/",
        },
        {
            "name": "The Basin",
            "address": "Franconia",
            "description": "Intermediate hike with a beautiful waterfall.",
            "url": "https://www.cannonmt.com/things-to-do/activities/the-basin",
        },
        {
            "name": "The Flume Gorge",
            "address": "Lincoln",
            "description": "800 foot long gorge with granite walls 12 to 20 feet apart towering 70 to 90 feet over your head. Has films and interpretive foot trails.",
            "url": "https://www.nhstateparks.org/visit/state-parks/flume-gorge",
        },
        {
            "name": "Polly’s Pancake Parlor",
            "address": "Sugar Hill",
            "description": "Iconic breakfast spot for the best pancakes in New England!",
            "url": "https://pollyspancakeparlor.com/",
        },
        {
            "name": "Woodstock Inn Brewery",
            "address": "North Woodstock",
            "description": "Large bar and restaurant with their own line of craft beer and the largest plate of nachos in the area.",
            "url": "https://www.woodstockinnbrewery.com/",
        },
        {
            "name": "The Mount Washington",
            "address": "Bretton Woods",
            "description": "Visit the iconic grand hotel, The Mount Washington in Bretton Woods, and enjoy a drink and views on the massive wrap around deck. ",
            "url": "https://www.omnihotels.com/hotels/bretton-woods-mount-washington",
        }]
    },
];

export default THINGS_TO_DO;