import React from 'react';
import MainContent from '../../components/main-content/mainContent';
import AddTodo from '../../components/todo/addTodo';
import VisibleTodoList from '../../components/todo/visibleTodoList';
import Footer from '../../components/todo/footer';

const mainContent = {
  title: "Todo",
  content: "Todos goes here"
}

const Todo = (props) => {
  return (
    <div>
    <MainContent title={mainContent.title} content={mainContent.content} />
    <AddTodo />
    <VisibleTodoList />
    <Footer />
    </div>
  );
}

export default Todo;