import ColorPalette from '../pages/color-palette/color-palette';
import ContactPage from '../pages/contact/contact';
import MapPage from '../pages/events/map';
import GridDemo from '../pages/grid-demo/gridDemo';
import Home from '../pages/home/home';
import Login from '../pages/login/login';
import Registry from '../pages/registry/registry';
import RSVP from '../pages/rsvp/rsvp';
import RSVPThanks from '../pages/rsvp/thank-you';
import Todo from '../pages/todo/todo';
import Accommodations from '../pages/travel/accommodations';
import ThingsToDo from '../pages/travel/things-to-do';
import Travel from '../pages/travel/travel';
import Covid19 from '../pages/covid-19/covid-19';

export const pageRoutes = [
    {
      title: 'Home',
      url: '/',
      component: Home,
      navRoute: true
    },
    {
      title: 'RSVP',
      url: '/rsvp',
      component: RSVP,
      navRoute: true,
      subheader: ['Kindly reply by August 14, 2020'],
    },
    {
      title: "Covid-19",
      url: '/covid-19',
      component: Covid19,
      navRoute: true,
      subheader: false,
      // subheader: ["PARTY'S STILL ON!", "Mill and I hope you're doing well - we are hunkered down here in New York and wanted to reach out on our latest thinking with regards to the impact of the coronavirus on our wedding in September. We also wanted to say again how much it means to us for your continued love and support!", "We've been monitoring the coronavirus situation in New Hampshire as closely as we can, and the long story short is, we still hope you can make it! We are seeing positive signs that New Hampshire is on track for us to move forward without needing to make any major changes to how we've envisioned our wedding, and are working with our vendors to adhere to any new guidelines already established.", "However, we want to share that there is still a small chance that things change, as we've sadly experienced throughout the last couple of months. We are considering that either a) we learn new information that makes it unsafe or impossible for people to travel or attend an event or b) the state of New Hampshire issues new guidance that limits celebration attendance. In order to reduce the chance of either of these things happening with short notice, we plan to gather as much information as we can between now and July 15th (New Tax Day 👀) that will confirm if proceeding as planned is the right thing to do. If we aren't able to celebrate with you all on September 19th, we are still planning on getting married privately rather than postponing the date and extending our engagement, and we will plan on celebrating with you the next time we are able to safely gather together.", "We've posted some helpful links from the state of New Hampshire that we are checking regularly below.", "With Love, Mill & Vince"],
    },
    {
      title: 'Events',
      url: '/events',
      component: MapPage,
      navRoute: true,
      header: true,
      footer: true,
      subheader: false,
      // subheader: ['Our wedding will take place on Saturday, September 19, 2020 in Bristol, New Hampshire at Our Lady of Grace Church with dinner and dancing following at The Barn at Meadow Wind. We look forward to a joyful celebration!'],
    }, 
    {
      title: 'Travel',
      url: '/travel',
      component: Travel,
      navRoute: true,
      subheader: ['Please check back closer to the wedding weekend for shuttle details from the select locations to and from the ceremony and reception.'],
    },
    {
      title: 'Accommodations',
      url: '/accommodations',
      component: Accommodations,
      navRoute: true,
      subheader: ["The Lakes Region area is lovely in the Fall! Mid-September marks the beginning of the Leaf Peeping season, so we do recommend to book as far in advance as possible. We've included any seasonal requirements below to help guide your selection.","If you're looking for a special weekend, we recommend the quintessential New Hampshire Bed & Breakfast experience, and if you're planning on extending your stay or lodging in a group, you may want to explore a rental service like Airbnb along Newfound Lake or the condos at Tenney Mountain Resort.", "Please check out the Travel section for shuttle details on the day of the wedding from select locations on this map!"],
    },
    {
      title: 'Things to Do',
      url: '/things-to-do',
      component: ThingsToDo,
      navRoute: true,
      subheader: ["We hope you spend some time getting to know, or revisiting, New Hampshire! The Lakes Region is full of local activities like hiking, spending time at any of the many lakes, antiquing, taking a scenic fall foliage stroll, and more.", "If you’re here for the first time, or up for a day adventure, we do recommend making the trip to the surrounding White Mountains (a 45 minute scenic drive) to take full advantage of the fall foliage and some well known and historical spots!", "We’ve shared some of our favorites as suggestions below to enjoy during your stay."],
    },
    {
      title: 'Registry',
      url: '/registry',
      component: Registry,
      navRoute: true,
      subheader: ["We're looking forward to starting our life together, and have added some items to our registry that will help us on our journey if you would like to send a gift -- please know that your love and support are the only gift we'll ever need!"],
    },
    {
      title: 'RSVPThanks',
      url: '/rsvp/thank-you',
      component: RSVPThanks,
      navRoute: false
    },
    {
      title: 'Color Palette',
      url: '/color-palette',
      component: ColorPalette,
      navRoute: false,
      header: false,
      footer: false
    },
    {
      title: 'Todo',
      url: '/todo',
      component: Todo,
      navRoute: false
    },
    {
      title: 'Login',
      url: '/login',
      component: Login,
      navRoute: false,
      public: true,
      header: false,
      footer: false
    },
    {
      title: 'Contact',
      url: '/contact',
      component: ContactPage,
      navRoute: false, public: false
    },
    {
      title: 'Grid Demo',
      url: '/grid-demo',
      component: GridDemo,
      navRoute: false
    },
  ];

export default pageRoutes