import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
  paper: {
    // padding: theme.spacing(2),
    // margin: 'auto',
    // maxWidth: 500,
    // backgroundColor: '#004500',
    // color: '#f1eeee',
  },
}));

export default function MainContent(props) {
  const classes = useStyles();
  const { title, content } = props;

  return (
    <Grid container spacing={2} alignContent="center" justify="center" align="center" alignItems="center">
    <Grid item xs={12} align="center" >
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      {/* <Divider /> */}
    </Grid>
    <Grid item xs={12} sm={8} md={4} >
      <Paper className={classes.paper}>{content}</Paper>
    </Grid>
    </Grid>
  );
}

MainContent.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
};
